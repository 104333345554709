import React, { useEffect, useState } from "react";
import List from "../../list/list";
import moment from "moment";
import Token from "../../auth/Token";
import dataService from "../../../services/data.service";
import Datatable from "../../dataTable/DataTable";
import Breadcrumb from "../../Breadcrumb";
import Parser from "html-react-parser";
import { useTranslation } from "react-i18next";
const AccountList = () => {
  const { t, i18n } = useTranslation();
  const config = {
    create: {
      link: "./create",
      icon: "icon-Plus",
      title: t("تعریف حساب"),
    },
  };
  const columns = [
    {
      key: "id",
      title: t("الترتیب"),
      value: function (model) {
        return model.id;
      },
    },
    {
      key: "name",
      title: t("الاسم"),
      value: function (model) {
        return model.name;
      },
    },
    {
      key: "nationalCode",
      title: t("رقم الهویه"),
      value: function (model) {
        return model.nationalCode;
      },
    },
    {
      key: "mobile",
      title: t("الموبايل"),
      value: function (model) {
        return model.mobile;
      },
    },
    {
      key: "description",
      title: t("التفاضیل"),
      value: function (model) {
        return model.description;
      },
    },
    {
      key: "type",
      title: t("نوع الحساب"),
      value: function (model) {
        if (model.type === 1) {
          return Parser(
            '<span class="badge badge-success">' + t("عام") + "</span>"
          );
        } else {
          return Parser(
            '<span class="badge badge-danger">' + t("الصندوق") + "</span>"
          );
        }
      },
    },
    {
      key: "status",
      title: t("الفعال"),
      value: function (model) {
        if (model.status === "1") {
          return Parser(
            '<span class="badge badge-success">' + t("فعال") + "</span>"
          );
        } else {
          return Parser(
            '<span class="badge badge-danger">' + t("غیرفعال") + "</span>"
          );
        }
      },
    },
    {
      key: "name",
      title: "",
      value: function (model) {
        return "";
      },
    },
  ];
  const actions = [
    {
      link: "/",
      name: t("home"),
    },
    {
      link: "/setting",
      name: t("اعدادات"),
    },
    {
      link: "/account/update/{id}",
      icon: "icon icon-Edit",
      type: "link",
    },
    {
      link: "/account/delete/{id}",
      icon: "icon icon-Cancel",
      type: "delete",
    },
  ];
  const breadcrumb = [
    {
      link: "/",
      name: t("home"),
    },
    {
      link: "/account",
      name: t("قائمة"),
    },
  ];
  const { token } = Token();
  const ds = new dataService(token, "accounts");
  return (
    <div class="col-md-12">
      <Breadcrumb data={breadcrumb} />
      <Datatable
        entity="accounts"
        dataService={ds}
        title={t("account")}
        columns={columns}
        actions={actions}
        config={config}
      />
    </div>
  );
};
export default AccountList;
