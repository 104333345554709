import React, { useState } from "react";
import Token from "../../auth/Token";
import dataService from "../../../services/data.service";
import { Button, Modal } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import AsyncSelectWithAdd from "../../async/AsyncSelectWithAdd";
const CurrencyPrice = () => {
  const { t } = useTranslation();
  const { token } = Token();
  const ds = new dataService(token, "currencies");
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [purchasePrice, setPurchasePrice] = useState("");
  const [salesPrice, setSalesPrice] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleCurrencyChange = (selectedOption) => {
    setSelectedCurrency(selectedOption);
    setPurchasePrice(selectedOption.purchasePrice);
    setSalesPrice(selectedOption.salesPrice);
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCurrency) {
      NotificationManager.error(t("يرجى اختيار العملة المطلوبة."), t("error"));
      return;
    }
    ds.update(selectedCurrency.value, {
      name: selectedCurrency.label,
      purchase_price: purchasePrice,
      sales_price: salesPrice,
    })
      .then((response) => {
        if (response.data.success)
          NotificationManager.success(response.data.data);
        setSelectedCurrency(null);
        setPurchasePrice("");
        setSalesPrice("");
        setShowModal(false);
      })
      .catch((e) => {
        NotificationManager.error(e.message, t("error"));
        setShowModal(false);
      });
  };
  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <div className="d-inline">
      <button className="ml-3" onClick={() => setShowModal(true)}>
        <span className="icon icon-Money-2" />
      </button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>{t("تعديل سعر العملة")}</Modal.Title>
          <Button
            className="float-start m-0"
            variant="link"
            onClick={handleClose}
          >
            <span className="icon icon-Cancel text-dark" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit} className=" text-right">
            <div className="form-group">
              <div className="title">{t("اختر نوع العمل")} :</div>
              <div className="input">
                <AsyncSelectWithAdd
                  model="currencies"
                  name="currency_id"
                  onChange={handleCurrencyChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="sales-price-input">{t("سعر الشراء")}</label>
              <input
                type="number"
                className="form-control"
                id="sales-price-input"
                value={salesPrice}
                onChange={(e) => setSalesPrice(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="purchase-price-input">{t("سعر البيع")}</label>
              <input
                type="number"
                className="form-control"
                id="purchase-price-input"
                value={purchasePrice}
                onChange={(e) => setPurchasePrice(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              {t("تسجیل")}
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default CurrencyPrice;
