import React, { useEffect, useState } from "react";
import Datatable from "../dataTable/DataTable";
import dataService from "../../services/data.service";
import Token from "../auth/Token";
import Parser from "html-react-parser";
import moment from "moment";
import AccountingDataTable from "../dataTable/AccountingDataTable";
import Breadcrumb from "../Breadcrumb";
import ChequeDataTable from "./chequeDataTable";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";
const ChequeList = (props) => {
  const { t } = useTranslation();
  const config = {
    export: {
      link: "./export",
      icon: "icon-Save",
      title: "",
    },
  };
  const columns = [
    {
      key: "id",
      title: t("الترتیب"),
      value: function (model) {
        return model.id;
      },
    },
    {
      key: "number",
      title: t("الرقم الحواله"),
      value: function (model) {
        return model.number;
      },
    },
    {
      key: "customer_rec_name",
      title: t("الحساب المستلم"),
      value: function (model) {
        return model?.in?.account?.name;
      },
    },
    {
      key: "customer_rec",
      title: t("المستفیذ"),
      value: function (model) {
        return model?.in?.customer?.name;
      },
    },
    {
      key: "price",
      title: t("مبلغ الحواله"),
      value: function (model) {
        return (
          <NumberFormat
            value={model.price}
            displayType={"text"}
            thousandSeparator={true}
            prefix={""}
          />
        );
      },
    },
    {
      key: "customer_snd_name",
      title: t("حساب الدافع"),
      value: function (model) {
        return model?.out?.account?.name;
      },
    },
    {
      key: "customer_snd",
      title: t("المرسل"),
      value: function (model) {
        return model?.out?.customer?.name;
      },
    },
    {
      key: "date",
      title: t("تاریخ الحواله"),
      value: function (model) {
        return moment(model.date).format("YYYY/MM/DD");
      },
    },
    {
      key: "currency_id",
      title: t("العمله"),
      value: function (model) {
        return model?.currency?.name;
      },
    },
    {
      key: "description",
      title: t("الملاحظات"),
      value: function (model) {
        return model?.description;
      },
    },
    {
      key: "code",
      title: t("الرمز الحواله"),
      value: function (model) {
        return model?.code;
      },
    },
    {
      key: "user",
      title: t("المستخدم"),
      value: function (model) {
        return model?.username;
      },
    },
  ];
  const actions = [
    {
      link: "/cheque/{id}",
      icon: "icon icon-Edit",
      type: "link",
    },
    {
      link: "/cheque/delete/{id}",
      icon: "icon icon-Cancel",
      type: "delete",
    },
  ];
  const { token } = Token();
  const ds = new dataService(token, "cheque");
  const breadcrumb = [
    {
      link: "/",
      name: t("home"),
    },
    {
      link:
        props.type === "deposit"
          ? "/transfer/list/deposit"
          : "/transfer/list/withdraw",
      name: props.type === "deposit" ? t("قائمة المصروف له") : t("قائمة المصروف لنا"),
    },
  ];
  return (
    <>
      <Breadcrumb data={breadcrumb} />
      <ChequeDataTable
        entity="transaction"
        type={props.type}
        dataService={ds}
        columns={columns}
        actions={actions}
        config={config}
      />
    </>
  );
};
export default ChequeList;
