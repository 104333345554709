import React, { useState } from "react";
import Dashboard from "../dashboard/dashboard";
import Breadcrumb from "../Breadcrumb";
import { useTranslation } from "react-i18next";
const Home = () => {
  const { t, i18n } = useTranslation();
  const [account, setAccount] = useState(null);
  const breadcrumb = [
    {
      link: "/",
      name: t("home"),
    },
  ];
  return (
    <div>
      <Breadcrumb data={breadcrumb} />
      <Dashboard onSelectActiveAccount={setAccount} />
    </div>
  );
};
export default Home;
