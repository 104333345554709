import React from "react";
const Logo = (props) => {
  return (
    <img
      src="./assets/images/logo.png"
      width={props.width}
      height={props.height}
      alt="logo"
    />
  );
};
export default Logo;
