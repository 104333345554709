import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import "../dataTable/dataTable.css";
import { useSelector } from "react-redux";
import "react-notifications/lib/notifications.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { BaseUrl } from "../../utilities/StaticProvider";
import moment from "moment";
import jsPDF from "jspdf";
import Token from "../auth/Token";
import { applyPlugin } from "jspdf-autotable";
import Utilities from "../../utilities/utilities";
import ExportMethod from "../../helpers/export";
import { useTranslation } from "react-i18next";
import AsyncSelectWithAdd from "../async/AsyncSelectWithAdd";
applyPlugin(jsPDF);
export default function TransferDataTable(props) {
  const { t } = useTranslation();
  const account = useSelector((state) => state.account);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: state.isFocused ? "#fff" : "#e8e8e8",
      borderRadius: "8px",
      borderColor: "#e8e8e8",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "red" : "blue",
      },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
  };
  const [selectedDepositOption, setSelectedDepositOption] = useState(null);
  const [selectedWithdrawOption, setSelectedWithdrawOption] = useState(null);
  const [selectedDepositSubAccountOption, setSelectedDepositSubAccountOption] =
    useState(null);
  const [
    selectedWithdrawSubAccountOption,
    setSelectedWithdrawSubAccountOption,
  ] = useState(null);
  const [fromDate, setFromDate] = useState(
    new Date().setMonth(new Date().getMonth() - 1)
  );
  const [toDate, setToDate] = useState(new Date());
  const { token } = Token();
  const [state, setState] = useState({
    entities: {
      data: [],
      models: "",
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 20,
        to: 1,
        total: 1,
      },
    },
    first_page: 1,
    current_page: 1,
    sorted_column: "accounting." + props.columns[0].key,
    offset: 4,
    order: "asc",
    q: "",
    deposit_account_id: props.type === "deposit" && account ? account.id : null,
    withdraw_account_id:
      props.type === "withdraw" && account ? account.id : null,
    currency_id: null,
    from: moment(fromDate).startOf("day").format("YYYY/MM/DD HH:mm:ss"),
    to: moment(toDate).endOf("day").format("YYYY/MM/DD HH:mm:ss"),
    type: props.type === "depos",
    loader: true,
  });
  const navigate = useNavigate();
  const [subaccounts, setSubAccounts] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const loadSubAccounts = async () => {
    const response = await fetch(BaseUrl + "subaccount");
    const models = await response.json();
    setSubAccounts(models.data);
  };
  const loadCurrencies = async () => {
    const response = await fetch(BaseUrl + "currency");
    const models = await response.json();
    setCurrencies(models.data);
    return models.data;
  };
  function fetchEntities() {
    return new Promise((resolve, reject) => {
      setState({
        ...state,
        loader: true,
      });
      return props.dataService
        .getAll({
          page: state.current_page,
          column: state.sorted_column,
          order: state.order,
          number: state.number,
          reason: state.reason,
          description: state.description,
          deposit_account_id: state.deposit_account_id,
          withdraw_account_id: state.withdraw_account_id,
          from: state.from,
          to: state.to,
          type: state.type,
          currency_id: state.currency_id,
          q: state.q,
          per_page: state.entities.meta.per_page,
        })
        .then((response) => {
          let data = response.data.data.map((entity) => {
            return props.columns
              .map((item) => item.key)
              .reduce(
                (a, e) => (
                  (a[e] = props.columns
                    .find((item) => item.key === e)
                    .value(entity)),
                  a
                ),
                {}
              );
          });
          setState({
            ...state,
            entities: {
              ...state.entities,
              data: data,
              meta: response.data.meta,
              models: response.data.data,
            },
            loader: false,
          });
          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    });
  }
  function changePage(pageNumber) {
    state.current_page = pageNumber;
    fetchEntities();
  }
  function columnHead(value) {
    return value.split("_").join(" ").toUpperCase();
  }
  function pagesNumbers() {
    if (!state.entities.meta.to) {
      return [];
    }
    let from = state.entities.meta.current_page - state.offset;
    if (from < 1) {
      from = 1;
    }
    let to = from + state.offset * 2;
    if (to >= state.entities.meta.last_page) {
      to = state.entities.meta.last_page;
    }
    let pagesArray = [];
    for (let page = from; page <= to; page++) {
      pagesArray.push(page);
    }
    return pagesArray;
  }
  useEffect(async () => {
    if (account) {
      if (props.type === "deposit") {
        setSelectedDepositOption({
          value: account.id,
          label: account.name,
        });
      } else {
        setSelectedWithdrawOption({
          value: account.id,
          label: account.name,
        });
      }
    }
    await loadSubAccounts();
    await loadCurrencies();
    //let's go
    fetchEntities().then((result) => {
      if (props.onSelect) {
        !account && result[0] && props.onSelect(result[0].id);
      }
    });
    setState({
      ...state,
      entities: {
        ...state.entities,
        data: [],
      },
      loader: false,
    });
  }, []);
  function tableHeads() {
    let icon;
    if (state.order === "asc") {
      icon = <i className="fas fa-arrow-up" />;
    } else {
      icon = <i className="fas fa-arrow-down" />;
    }
    return props.columns.map((column) => {
      return (
        <th
          className="table-head"
          key={column.key}
          onClick={() => sortByColumn(column.key)}
        >
          {columnHead(column.title)}
          {column.key === state.sorted_column && icon}
        </th>
      );
    });
  }
  async function navigateToModelEdit(id) {
    const models = state.entities.models;
    const model = models.find((item) => item.id === id);
    if (+model.entity_type === 1 || +model.entity_type === 2) {
      if (+model.type === 1) {
        navigate("/transfer/withdraw/" + model.entity_id);
      } else {
        navigate("/transfer/deposit/" + model.entity_id);
      }
    } else if (+model.entity_type === 3 || +model.entity_type === 4) {
      if (+model.type === 1) {
        navigate("/cheque-out/" + model.entity_id);
      } else {
        navigate("/cheque-in/" + model.entity_id);
      }
    } else if (+model.entity_type === 5 || +model.entity_type === 6) {
      if (+model.type === 1) {
        navigate("/exchange/buyer/" + model.entity_id);
      } else {
        navigate("/exchange/seller/" + model.entity_id);
      }
    } else {
      navigate("/");
    }
  }
  function filter(event) {
    event.preventDefault();
    fetchEntities();
  }
  function sortByColumn(column) {
    if (column === state.sorted_column) {
      state.order === "asc"
        ? setState(
            {
              ...state,
              order: "desc",
              current_page: state.first_page,
            },
            () => {
              fetchEntities();
            }
          )
        : setState(
            {
              ...state,
              order: "asc",
            },
            () => {
              fetchEntities();
            }
          );
    } else {
      setState(
        {
          ...state,
          sorted_column: column,
          order: "asc",
          current_page: state.first_page,
        },
        () => {
          fetchEntities();
        }
      );
    }
  }
  function renderPagination() {
    return (
      <div className="col-md-12">
        {state.entities.data && state.entities.data.length > 0 && (
          <nav>
            <div className="customer-pagination pagination">
              <button
                disabled={1 === state.entities.meta.current_page}
                onClick={() => {
                  changePage(state.entities.meta.current_page - 1);
                }}
                className={
                  1 === state.entities.meta.current_page
                    ? "next"
                    : "next active"
                }
              >
                <span className="icon icon-Arrow pink reverse" />
              </button>

              <div className="indexes">
                {Array(
                  state.entities.meta.last_page <= 5
                    ? state.entities.meta.last_page
                    : 5
                )
                  .fill(0)
                  .map((item, index) => {
                    index =
                      state.entities.meta.last_page <= 5
                        ? index
                        : index + state.entities.meta.current_page - 1;
                    return (
                      <button
                        onClick={() => changePage(index + 1)}
                        className={
                          state.entities.meta.current_page === index + 1
                            ? "index active"
                            : "index"
                        }
                      >
                        {index + 1}
                      </button>
                    );
                  })}
              </div>

              <button
                disabled={
                  state.entities.meta.last_page ===
                  state.entities.meta.current_page
                }
                onClick={() => {
                  changePage(state.entities.meta.current_page + 1);
                }}
                className={
                  state.entities.meta.last_page ===
                  state.entities.meta.current_page
                    ? "prev"
                    : "prev active"
                }
              >
                <span className="icon icon-Arrow" />
              </button>
            </div>
          </nav>
        )}
      </div>
    );
  }
  
  function getSubAccountOptions() {
    return (
      subaccounts &&
      subaccounts.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    );
  }
  function applyFilter(name = "", value = "") {
    if (name === "deposit_account_id") {
      state.deposit_account_id = value;
    } else if (name === "withdraw_account_id") {
      state.withdraw_account_id = value;
    } else if (name === "currency_id") {
      state.currency_id = value;
    } else if (name === "number") {
      state.number = value;
    } else if (name === "description") {
      state.description = value;
    } else if (name === "reason") {
      state.reason = value;
    } else if (name === "from") {
      state.from = moment(value).startOf("day").format("YYYY/MM/DD HH:mm:ss");
      setFromDate(value);
    } else if (name === "to") {
      state.to = moment(value).endOf("day").format("YYYY/MM/DD HH:mm:ss");
      setToDate(value);
    } else if (name === "withdraw") {
      state.type = "withdraw";
    } else if (name === "deposit") {
      state.type = "deposit";
    } else {
      state.type = null;
      state.from = null;
      state.to = null;
    }
    fetchEntities();
  }
  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
  function onExport(e, type) {
    this.onDataRender();
    const { exportHeaders } = this.props;
    const { data, header } = this.raw;
    const exportData = ExportMethod[type](data, exportHeaders ? header : null);
    Utilities.download(exportData);
    this.setState({
      dropdown: false,
    });
    e.preventDefault();
  }
  function onPrint() {
    this.onDataRender();
    const { data, header } = this.raw;
    const table = ExportMethod.print(data, header);
    Utilities.print(table);
  }
  function renderFilters() {
    return (
      <div className="col-md-12">
        <form className="datatable-filterbox-accounting" onSubmit={filter}>
          <div className="form-row">
            <div className="col-md-2 form-group">
              <div className="title">{t("رقم الوصول")} :</div>
              <div className="input">
                <input
                  type="text"
                  name="number"
                  id="number"
                  className="form-control"
                  onChange={(event) =>
                    applyFilter("number", event.target.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-2 form-group">
              <div className="title">{t("form_date")} :</div>
              <div className="input">
                <DatePicker
                  name="from"
                  id="from"
                  className="form-control silver"
                  dateFormat="yyyy/MM/dd"
                  selected={fromDate}
                  strictParsing
                  closeOnScroll={true}
                  onChange={(date) => applyFilter("from", date)}
                />
              </div>
            </div>
            <div className="col-md-2 form-group">
              <div className="title">{t("to_date")} :</div>
              <div className="input">
                <DatePicker
                  name="from"
                  id="from"
                  selected={toDate}
                  className="form-control silver"
                  dateFormat="yyyy/MM/dd"
                  strictParsing
                  closeOnScroll={true}
                  onChange={(date) => applyFilter("to", date)}
                />
              </div>
            </div>
            <div className="col-md-3 form-group">
              <div className="title">{t("اسم لنا :")} :</div>
              <div className="input">
                <AsyncSelectWithAdd
                  model="accounts"
                  value={selectedDepositOption}
                  name="deposit_account_id"
                  styles={customStyles}
                  onChange={(e, triggeredAction) => {
                    setSelectedDepositOption(e);
                    if (e !== null) {
                      applyFilter("deposit_account_id", e);
                    } else {
                      applyFilter("deposit_account_id", {
                        value: null,
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-md-3 form-group">
              <div className="title">{t("اسم له :")} :</div>
              <div className="input">
                <AsyncSelectWithAdd
                  model="accounts"
                  value={selectedWithdrawOption}
                  name="withdraw_account_id"
                  styles={customStyles}
                  onChange={(e, triggeredAction) => {
                    setSelectedWithdrawOption(e);
                    if (e !== null) {
                      applyFilter("withdraw_account_id", e);
                    } else {
                      applyFilter("withdraw_account_id", {
                        value: null,
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-2 form-group">
              <div className="title">{t("لنا فرع الحساب")} :</div>
              <div className="input">
                <Select
                  options={getSubAccountOptions()}
                  value={selectedWithdrawSubAccountOption}
                  name="withdraw_subaccount_id"
                  styles={customStyles}
                  className="minimal"
                  onChange={(e) =>
                    applyFilter("withdraw_subaccount_id", e.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-2 form-group">
              <div className="title">{t("له فرع الحساب")} :</div>
              <div className="input">
                <Select
                  options={getSubAccountOptions()}
                  value={selectedDepositSubAccountOption}
                  name="deposit_subaccount_id"
                  styles={customStyles}
                  className="minimal"
                  onChange={(e) =>
                    applyFilter("deposit_subaccount_id", e.value)
                  }
                />
              </div>
            </div>
            <div className="col-md-2 form-group">
              <div className="title">{t("نوع العمل")} :</div>
              <div className="input">
                <Select
                  options={
                    currencies &&
                    currencies.map((item) => {
                      return {
                        value: item.id,
                        label: item.name,
                      };
                    })
                  }
                  name="currency_id"
                  styles={customStyles}
                  isClearable
                  onChange={(e) =>
                    e
                      ? applyFilter("currency_id", e.value)
                      : applyFilter("currency_id", null)
                  }
                  className="minimal"
                />
              </div>
            </div>
            <div className="col-md-3 form-group">
              <div className="title">{t("سبب الوصول")} :</div>
              <div className="input">
                <input
                  type="text"
                  name="reason"
                  id="reason"
                  className="form-control"
                  onChange={debounce(function (event) {
                    applyFilter("reason", event.target.value);
                  }, 500)}
                />
              </div>
            </div>
            <div className="col-md-3 form-group">
              <div className="title">{t("description")} :</div>
              <div className="input">
                <input
                  type="text"
                  name="description"
                  id="description"
                  className="form-control"
                  onChange={debounce(function (event) {
                    applyFilter("description", event.target.value);
                  }, 500)}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
  function list() {
    if (state.entities.data.length) {
      return state.entities.data.map((model) => {
        return (
          <tr
            key={model.id}
            className={account && account.id === model.id ? "active" : ""}
            onClick={(event) => props.onSelect && props.onSelect(model.id)}
            onDoubleClick={() => navigateToModelEdit(model.id)}
          >
            {Object.keys(model).map((key) => (
              <td key={key}>{model[key]}</td>
            ))}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={props.columns.length} className="text-center">
            {t("No Records Found.")}
          </td>
        </tr>
      );
    }
  }
  function exportPDF() {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "accounting";
    const headers = [["id", "date", "type", "code"]];
    const data = state.entities.data.map((item) => [
      item.id,
      item.date,
      item.type,
      item.number,
    ]);
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
  }
  function render() {
    return (
      <div className="col-md-12">
        <div className="datatable">
          {props.config.export && (
            <div className="datatable-header export-div">
              <button className="btn export-btn" onClick={() => exportPDF()}>
                <span className={props.config.export.icon} />
              </button>
              <h5> {props.config.export.title}</h5>
            </div>
          )}
          {state.loader && <ShimmerTable row={4} />}
          {!state.loader && (
            <table className="table">
              <thead>
                <tr>{tableHeads()}</tr>
              </thead>
              <tbody>{list()}</tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="form-row">
      {renderFilters()}
      {render()}
      {renderPagination()}
    </div>
  );
}
