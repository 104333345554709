const initialState = {
  account: null,
  menu: false,
};
const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACCOUNT": {
      return {
        ...state,
        account: action.payload,
      };
    }
    case "TOGGLE_MENU": {
      return {
        ...state,
        menu: action.payload,
      };
    }
    case "CLOSE_MENU": {
      return {
        ...state,
        menu: "close",
      };
    }
    default: {
      return state;
    }
  }
};
export default appReducer;
