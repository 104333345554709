import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { BrowserRouter } from "react-router-dom";
import appReducer from "./reducer/accountReducer";
import { createStore } from "redux";
import { Provider } from "react-redux";
import LoadingIndicator from "./components/loading/LoadingIndicator";
import "./i18n";
const store = createStore(appReducer);
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
      <LoadingIndicator />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
