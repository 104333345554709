import $ from "jquery";
function AlertWithDetails(
  details,
  className,
  history = null,
  redirectUrl = null
) {
  console.log(details.message.message);
  let validStart;
  let validEnd;
  if (
    details.hasOwnProperty("message") &&
    details.message.hasOwnProperty("message")
  ) {
    validStart = " <p><b>" + details.message.message + "</b></p>\n";
  } else if (details.hasOwnProperty("data")) {
    validStart =
      " <p><b>" +
      details.message +
      "</b></p><p>\n" +
      "                            <ul>\n";
    for (const [key, value] of Object.entries(details.data)) {
      value.forEach((item) => {
        validStart += "<li>" + item + "</li>";
      });
    }
    validEnd =
      "                            </ul>\n" + "                        </p>";
  } else {
    validStart =
      " <p><b>" +
      "خطای داخلی سرور . لطفا بعدا دوباره امتحان کنید" +
      "</b></p>\n";
  }
  let concat = validStart + validEnd;
  $("#message").toggleClass(className);
  $("#message").show();
  $("#message").html(concat);
  setTimeout(() => {
    $(".alert").slideUp(10000);
  }, 4000);
  if (history && redirectUrl) {
    setTimeout(() => {
      history.replace(redirectUrl);
    }, 5000);
  }
}
export default AlertWithDetails;
