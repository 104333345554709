import React, { useEffect, useState } from "react";
// import Parser from "html-react-parser";
import moment from "moment";
import Token from "../auth/Token";
import dataService from "../../services/data.service";
import Datatable from "../dataTable/DataTable";
import Breadcrumb from "../Breadcrumb";
import { useTranslation } from "react-i18next";
const CorrespondList = () => {
  const { t, i18n } = useTranslation();
  const config = {
    create: {
      link: "./create",
      icon: "icon-Plus",
      title: t("مطابقت"),
    },
  };
  const columns = [
    {
      key: "id",
      title: t("الترتیب"),
      value: function (model) {
        return model.id;
      },
    },
    {
      key: "name",
      title: t("اسم الحساب"),
      value: function (model) {
        return model.name;
      },
    },
    {
      key: "created_at",
      title: t("تاريخ الانشاء"),
      value: function (model) {
        return moment(model.created_at).format("YYYY/MM/DD hh:mm:ss");
      },
    },
  ];
  const actions = [
    {
      link: "/correspond/{id}",
      icon: "icon icon-Edit",
      type: "link",
    },
  ];
  let dataBradCrumb = [
    {
      link: "/",
      name: t("home"),
    },
    {
      link: "/correspond",
      name: t("قائمة"),
    },
  ];
  const { token } = Token();
  const ds = new dataService(token, "correspond");
  return (
    <div class="col-md-12">
      <Breadcrumb data={dataBradCrumb} />
      <Datatable
        entity="users"
        dataService={ds}
        columns={columns}
        actions={actions}
        config={config}
      />
    </div>
  );
};
export default CorrespondList;
