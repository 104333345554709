import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import "./dataTable.css";
import { useSelector } from "react-redux";
import "react-notifications/lib/notifications.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { BaseUrl } from "../../utilities/StaticProvider";
import moment from "moment";
import jsPDF from "jspdf";
import Token from "../auth/Token";
import { applyPlugin } from "jspdf-autotable";
import Utilities from "../../utilities/utilities";
import ExportMethod from "../../helpers/export";
import NumberFormat from "react-number-format";
import "./Al-Jazeera-Arabic-Regular-normal.js";
import { useTranslation } from "react-i18next";
import AsyncSelectWithAdd from "../async/AsyncSelectWithAdd";
applyPlugin(jsPDF);
export default function AccountingDataTable(props) {
  const { t } = useTranslation();
  const account = useSelector((state) => state.account);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: state.isFocused ? "#fff" : "#e8e8e8",
      borderRadius: "8px",
      borderColor: "#e8e8e8",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "red" : "blue",
      },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [currenciesColumns, setCurrenciesColumns] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currenciesLoaded, setCurrenciesLoaded] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(new Date());
  const [body, setBody] = useState({
    entities: {
      data: [],
      transactions_by_currency: [],
      models: "",
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 20,
        to: 1,
        total: 1,
      },
    },
    first_page: 1,
    current_page: 1,
    sorted_column: props.columns[0].key,
    offset: 4,
    order: "asc",
    q: "",
    account_id: account ? account.id : null,
    currency_id: null,
    from: null,
    to: moment(toDate).endOf("day").format("YYYY/MM/DD HH:mm:ss"),
    type: null,
  });
  const date_options = [
    { value: "daily", label: t("daily") },
    { value: "weekly", label: t("weekly") },
    { value: "monthly", label: t("monthly") },
    { value: "total", label: t("الکل") },
  ];
  const [selectedDateOption, setSelectedDateOption] = useState(date_options[1]); // default to 'total'
  useEffect(() => {
    switch (selectedDateOption.value) {
      case "daily":
        let from_date = new Date(new Date().setDate(new Date().getDate() - 1));
        setFromDate(from_date);
        body.from = from_date;
        break;
      case "weekly":
        let weekDate = new Date(new Date().setDate(new Date().getDate() - 7));
        setFromDate(weekDate);
        body.from = weekDate;
        break;
      case "monthly":
        let monthDate = new Date(
          new Date().setMonth(new Date().getMonth() - 1)
        );
        setFromDate(monthDate);
        body.from = monthDate;
        break;
      case "total":
        setFromDate(null);
        body.from = null;
        break;
      default:
        break;
    }
    setToDate(new Date());
    body.to = new Date();
    fetchEntities();
  }, [selectedDateOption]);
  const navigate = useNavigate();
  const [currencies, setCurrencies] = useState([]);
  const loadCurrencies = async () => {
    setCurrenciesLoaded(false);
    const response = await fetch(BaseUrl + "currency");
    const models = await response.json();
    setCurrencies(models.data);
    setCurrenciesLoaded(true);
    return models.data;
  };
  const [abortController, setAbortController] = useState(null);

  useEffect(() => {
    return () => {
      // Cleanup function to cancel the request when component unmounts
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);

  function fetchEntities() {
    if(!currenciesLoaded) return;

    const fetchData = async () => {
      if (abortController) {
        // Cancel previous request if it exists
        abortController.abort();
      }

      const newAbortController = new AbortController();
      setAbortController(newAbortController);
      setLoader(true);

      try {
        const response = await props.dataService.getAllFiltered(
          {
            filter: {
              account_id: body.account_id,
              from: body.from,
              to: body.to,
              type: body.type,
              currency_id: body.currency_id,
            },
            pagination: {
              page: body.current_page,
              per_page: body.entities.meta.per_page,
            },
            order: {
              column: body.sorted_column,
              order: body.order,
            },
          },
          { signal: newAbortController.signal } // Pass the signal to the fetch request
        );

        let data = response.data.data.map((entity) => {
          const allColumns = [...props.columns, ...currenciesColumns];
          return allColumns
            .map((item) => item.key)
            .reduce(
              (a, e) => (
                (a[e] = allColumns
                  .find((item) => item.key === e)
                  .value(entity)),
                a
              ),
              {}
            );
        });

        setBody({
          ...body,
          entities: {
            ...body.entities,
            data: data,
            meta: response.data.meta,
            transactions_by_currency: response.data.transactions_by_currency,
            models: response.data.data,
          },
        });

        setLoader(false);
        // Resolve the data promise
        return data;
      } catch (error) {
        if (error.name === "AbortError") {
          // Request was aborted, no need to handle the error
          return;
        }

        setLoader(false);
        console.error(error);
        throw error;
      }
    };

    return fetchData();
  }
  function changePage(pageNumber) {
    body.current_page = pageNumber;
    fetchEntities();
  }
  function columnHead(value) {
    return value.split("_").join(" ").toUpperCase();
  }
  function pagesNumbers() {
    if (!body.entities.meta.to) {
      return [];
    }
    let from = body.entities.meta.current_page - body.offset;
    if (from < 1) {
      from = 1;
    }
    let to = from + body.offset * 2;
    if (to >= body.entities.meta.last_page) {
      to = body.entities.meta.last_page;
    }
    let pagesArray = [];
    for (let page = from; page <= to; page++) {
      pagesArray.push(page);
    }
    return pagesArray;
  }

  useEffect(() => {
    const fetchData = async () => {
      if (account) {
        setSelectedOption({
          value: account.id,
          label: account.name,
        });
      }
      const currencies = await loadCurrencies();
      const currenciesArray = body.currency_id
        ? currencies.filter((el) => el.id === body.currency_id)
        : currencies;

      const renderFormattedValue = (value, className, sign) => {
        return (
          <NumberFormat
            value={value}
            displayType={"text"}
            allowNegative={true}
            defaultValue={0}
            thousandSeparator={true}
            prefix={""}
            renderText={(formattedValue, customProps) => {
              return (
                <span className={"btn ltr " + className}>
                  {sign}
                  {formattedValue}
                </span>
              );
            }}
          />
        );
      };

      const updatedColumns = currenciesArray.flatMap((item) => {
        const keyPrice = item.name + "_price";
        const keyRemaining = item.name + "_remaining";

        const renderValue = (model, key, label, valueFunc) => {
          if (+item.id === +model.currency_id) {
            const value =
              typeof valueFunc === "function" ? valueFunc(model) : valueFunc;
            const className = value < 0 ? "btn-danger" : "btn-success";
            const sign = value < 0 ? "" : "+";
            return (
              <span key={key} className={"btn ltr " + className}>
                {sign}
                {value}
              </span>
            );
          }
          return 0;
        };

        return [
          {
            key: keyPrice,
            title: item.name,
            value: (model) =>
              renderValue(
                model,
                keyPrice,
                item.name,
                model.type === "1" ? -model.price : model.price
              ),
          },
          {
            key: keyRemaining,
            title: t("المتبقي"),
            value: (model) =>
              renderValue(
                model,
                keyRemaining,
                t("المتبقي"),
                Array.isArray(model.amounts)
                  ? model.amounts.find((a) => +a.currency_id === +item.id)
                      ?.amount
                  : model.amounts?.currency_id === item.id
                  ? model.amounts.current_amount
                  : 0
              ),
          },
        ];
      });
      // Assign the updated columns to a different property, e.g., updatedColumns
      setCurrenciesColumns(updatedColumns);
    };

    fetchData();
  }, []);

  useEffect(() => {
    fetchEntities();
  }, [currenciesColumns]);

  function tableHeads() {
    const selectedCurrency = currencies.find(
      (obj) => obj.id === body.currency_id
    );
    const currenciesTitles = selectedCurrency ? [selectedCurrency.name] : [];
    let icon =
      body.order === "asc" ? (
        <i className="fas fa-arrow-up" />
      ) : (
        <i className="fas fa-arrow-down" />
      );

    const allColumns = [...props.columns, ...currenciesColumns]; // Concatenate updatedColumns and props.columns

    return allColumns.map((column) => {
      const key = column.key.replace("_", "");
      if (
        selectedCurrency &&
        currenciesTitles.includes(key) &&
        selectedCurrency.name.trim() !== key.trim()
      ) {
        return null; // Don't show other currencies if the user selected one
      }

      return (
        <th
          className="table-head"
          key={column.key}
          onClick={() => sortByColumn(column.key)}
        >
          {columnHead(column.title)}
          {column.key === body.sorted_column && icon}
        </th>
      );
    });
  }
  async function navigateToModelEdit(id) {
    const models = body.entities.models;
    const model = models.find((item) => item.id === id);
    if (+model.entity_type === 1 || +model.entity_type === 2) {
      if (+model.type === 1) {
        navigate("/transfer/withdraw/" + model.entity_id);
      } else {
        navigate("/transfer/deposit/" + model.entity_id);
      }
    } else if (+model.entity_type === 3 || +model.entity_type === 4) {
      if (+model.type === 1) {
        navigate("/cheque-out/" + model.entity_id);
      } else {
        navigate("/cheque-in/" + model.entity_id);
      }
    } else if (+model.entity_type === 5 || +model.entity_type === 6) {
      if (+model.type === 1) {
        navigate("/exchange/buyer/" + model.entity_id);
      } else {
        navigate("/exchange/seller/" + model.entity_id);
      }
    } else {
      navigate("/");
    }
  }
  function filter(event) {
    event.preventDefault();
    fetchEntities();
  }
  function sortByColumn(column) {
    if (column === body.sorted_column) {
      body.order === "asc"
        ? setBody({
            ...body,
            order: "desc",
            current_page: body.first_page,
          })
        : setBody({
            ...body,
            order: "asc",
          });
      fetchEntities();
    } else {
      setBody({
        ...body,
        sorted_column: column,
        order: "asc",
        current_page: body.first_page,
      });
      fetchEntities();
    }
  }
  function renderPagination() {
    return (
      <div className="col-md-12">
        {body.entities.data && body.entities.data.length > 0 && (
          <nav>
            <div className="customer-pagination pagination">
              <button
                disabled={1 === body.entities.meta.current_page}
                onClick={() => {
                  changePage(body.entities.meta.current_page - 1);
                }}
                className={
                  1 === body.entities.meta.current_page ? "next" : "next active"
                }
              >
                <span className="icon icon-Arrow pink reverse" />
              </button>

              <div className="indexes">
                {Array(
                  body.entities.meta.last_page <= 5
                    ? body.entities.meta.last_page
                    : 5
                )
                  .fill(0)
                  .map((item, index) => {
                    index =
                      body.entities.meta.last_page <= 5
                        ? index
                        : index + body.entities.meta.current_page - 1;
                    return (
                      <button
                        key={index}
                        onClick={() => changePage(index + 1)}
                        className={
                          body.entities.meta.current_page === index + 1
                            ? "index active"
                            : "index"
                        }
                      >
                        {index + 1}
                      </button>
                    );
                  })}
              </div>

              <button
                disabled={
                  body.entities.meta.last_page ===
                  body.entities.meta.current_page
                }
                onClick={() => {
                  changePage(body.entities.meta.current_page + 1);
                }}
                className={
                  body.entities.meta.last_page ===
                  body.entities.meta.current_page
                    ? "prev"
                    : "prev active"
                }
              >
                <span className="icon icon-Arrow" />
              </button>
            </div>
          </nav>
        )}
      </div>
    );
  }
  function applyFilter(name = "", value = "") {
    if (name === "account_id") {
      body.account_id = value.value;
    } else if (name === "currency_id") {
      body.currency_id = value;
    } else if (name === "from") {
      if (value != null)
        body.from = moment(value).startOf("day").format("YYYY/MM/DD HH:mm:ss");
      else body.from = null;
      setFromDate(value);
    } else if (name === "to") {
      if (value != null)
        body.to = moment(value).endOf("day").format("YYYY/MM/DD HH:mm:ss");
      else body.to = null;
      setToDate(value);
    } else if (name === "correspond") {
      body.type = "correspond";
    } else if (name === "last_correspond") {
      body.type = "last_correspond";
    } else {
      body.type = null;
      body.from = null;
      body.to = null;
    }
    fetchEntities();
  }
  function onExport(e, type) {
    this.onDataRender();
    const { exportHeaders } = this.props;
    const { data, header } = this.raw;
    const exportData = ExportMethod[type](data, exportHeaders ? header : null);
    Utilities.download(exportData);
    this.setState({
      dropdown: false,
    });
    e.preventDefault();
  }
  function onPrint() {
    this.onDataRender();
    const { data, header } = this.raw;
    const table = ExportMethod.print(data, header);
    Utilities.print(table);
  }
  function renderFilters() {
    return (
      <div className="col-md-12">
        <form className="datatable-filterbox-accounting" onSubmit={filter}>
          <div className="form-row">
            <div className="col-md-3 form-group">
              <div className="title">{t("اسم المشتری")} :</div>
              <div className="input">
                <AsyncSelectWithAdd
                  model="accounts"
                  value={account && { label: account.name, value: account.id }}
                  name="account_id"
                  styles={customStyles}
                  onChange={(e) => {
                    setSelectedOption(e);
                    if (e !== null) {
                      applyFilter("account_id", e);
                    } else {
                      applyFilter("account_id", {
                        value: null,
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-md-3 form-group">
              <div className="title">{t("اختر نوع العمل")} :</div>
              <div className="input">
                <AsyncSelectWithAdd
                  model="currencies"
                  name="currency_id"
                  styles={customStyles}
                  onChange={(e) =>
                    e
                      ? applyFilter("currency_id", e.value)
                      : applyFilter("currency_id", null)
                  }
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 filter-check-items d-flex align-items-center">
              <label
                className="el-switch el-switch-sm el-switch-red"
                htmlFor="correspond"
              >
                <span className="margin-l">{t("فقط مطابقات")}</span>
                <input
                  type="checkbox"
                  name="type"
                  checked={body.type === "correspond"}
                  onChange={(date) =>
                    body.type === "correspond"
                      ? applyFilter()
                      : applyFilter("correspond", "set")
                  }
                  id="correspond"
                />
                <span className="el-switch-style" />
              </label>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 col-6 filter-check-items d-flex align-items-center">
              <label
                className="el-switch el-switch-sm el-switch-red"
                htmlFor="last_correspond"
              >
                <span className="margin-l">{t("من اخیر مطابقه")}</span>
                <input
                  type="checkbox"
                  checked={body.type === "last_correspond"}
                  name="type"
                  onChange={(date) =>
                    body.type === "last_correspond"
                      ? applyFilter()
                      : applyFilter("last_correspond", "set")
                  }
                  id="last_correspond"
                />
                <span className="el-switch-style" />
              </label>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12 filter-checks">
              <div className="form-row">
                <div className="col-md-3 form-group">
                  <div className="title">{t("date_period")} :</div>
                  <div className="input">
                    <Select
                      options={date_options}
                      styles={customStyles}
                      value={selectedDateOption}
                      onChange={setSelectedDateOption}
                    />
                  </div>
                </div>
                <div className="col-md-3 form-group">
                  <div className="title">{t("من تاریخ")} :</div>
                  <div className="input">
                    <DatePicker
                      name="from"
                      id="from"
                      className="form-control silver"
                      dateFormat="yyyy/MM/dd"
                      selected={fromDate}
                      strictParsing
                      closeOnScroll={true}
                      onChange={(date) => applyFilter("from", date)}
                    />
                  </div>
                </div>
                <div className="col-md-3 form-group">
                  <div className="title">{t("الی تاریخ")} :</div>
                  <div className="input">
                    <DatePicker
                      name="from"
                      id="from"
                      selected={toDate}
                      className="form-control silver"
                      dateFormat="yyyy/MM/dd"
                      strictParsing
                      closeOnScroll={true}
                      onChange={(date) => applyFilter("to", date)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
  function tableFoots() {
    if (
      !body.entities.transactions_by_currency ||
      body.entities.transactions_by_currency.length === 0
    ) {
      return null;
    }
    if (body.account_id) {
      if (body.currency_id != null && currencies) {
        // If currency ID is set and currencies exist, display one cell with currency name
        const selectedCurrency = body.entities.transactions_by_currency.find(
          (item) => item.currency_id === body.currency_id
        );
        const currencyName = selectedCurrency ? selectedCurrency.currency : "";
        return (
          <tfoot>
            <tr>
              <td colSpan="1" className="bg-info text-white">
                المتبقي:
              </td>
              <td colSpan="100%" className="bg-info text-white">
                {currencyName}: &nbsp;
                <NumberFormat
                  value={selectedCurrency.current_amount}
                  displayType={"text"}
                  allowNegative={true}
                  defaultValue={0}
                  thousandSeparator={true}
                  prefix={""}
                />
              </td>
            </tr>
          </tfoot>
        );
      } else if (currencies && currencies.length > 0) {
        // If currencies exist but currency ID is not set, display one cell with currency name for each currency
        return (
          <tfoot>
            <tr>
              <td colSpan="2" className="bg-info text-white">
                المتبقي:
              </td>
              {body.entities.transactions_by_currency.map((item) => (
                <td
                  colSpan="2"
                  key={item.wallet_id}
                  className="bg-info text-white"
                >
                  {item.currency} : &nbsp;
                  <NumberFormat
                    value={item.current_amount}
                    displayType={"text"}
                    allowNegative={true}
                    defaultValue={0}
                    thousandSeparator={true}
                    prefix={""}
                  />
                </td>
              ))}
              <td className="bg-info text-white" colSpan="100%"></td>
            </tr>
          </tfoot>
        );
      } else {
        // If currency ID is not set and no currencies exist, display only the "المتبقي:" cell
        return (
          <tfoot>
            <tr>
              <td colSpan="100%" className="bg-info text-white">
                {t("المتبقي")}:
              </td>
            </tr>
          </tfoot>
        );
      }
    } else {
      return null;
    }
  }
  function list() {
    if (body.entities.data.length) {
      let keys = [...body.entities.data];
      return keys.map((model, index) => {
        return (
          <tr
            key={model.id}
            className={account && account.id === model.id ? "active" : ""}
            onClick={(event) => props.onSelect && props.onSelect(model.id)}
            onDoubleClick={() => navigateToModelEdit(model.id)}
          >
            <td key="row">{index + 1}</td>
            {Object.keys(model)
              .filter((key) => key !== "id")
              .map((key) => (
                <td key={key}>{model[key]}</td>
              ))}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={props.columns.length} className="text-center">
            {t("No Records Found.")}
          </td>
        </tr>
      );
    }
  }

  function printReport() {
    const url = BaseUrl + props.entity;
    const qs = require("qs");
    const params = {
      print: true,
      filter: {
        account_id: body.account_id,
        from: body.from,
        to: body.to,
        type: body.type,
        currency_id: body.currency_id,
      },
      order: {
        column: body.sorted_column,
        order: body.order,
      },
    };
    const queryString = qs.stringify(params);
    const fullUrl = `${url}?${queryString}`;
    window.open(fullUrl, "_blank");
  }
  function render() {
    return (
      <div className="col-md-12">
        <div className="datatable">
          {props.config.export && (
            <div className="datatable-header export-div">
              <button className="btn" onClick={() => printReport()}>
                <span className={props.config.export.icon} />
              </button>
              <h5> {props.config.export.title}</h5>
            </div>
          )}
          {loader && <ShimmerTable row={4} />}
          {!loader && (
            <table className="table">
              <thead>
                <tr>{tableHeads()}</tr>
              </thead>
              <tbody>{list()}</tbody>
              {tableFoots()}
            </table>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="form-row">
      {renderFilters()}
      {render()}
      {renderPagination()}
    </div>
  );
}
