import React, { useEffect, useState } from "react";
import ChequeIn from "../cheque-item/ChequeIn";
import ChequeOut from "../cheque-item/ChequeOut";
import Button from "../button/Button";
import dataService from "../../services/data.service";
import FormGroup from "../formGroup/formGroup";
import Token from "../auth/Token";
import Breadcrumb from "../Breadcrumb";
import { useNavigate, useParams } from "react-router";
import translate from "../../message/avii";
import {
  isContainWhiteSpace,
  isEmpty,
  isLength,
  isUsername,
} from "../../shared/validator";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import axios from "axios";
import { BaseUrl } from "../../utilities/StaticProvider";
import DatePicker from "react-datepicker";
import { FormText } from "react-bootstrap";
import moment from "moment";
import connect from "react-redux/es/connect/connect";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const CorrespondForm = (props) => {
  const { t, i18n } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [state, setState] = useState({
    formData: {
      accountId: "",
      date: startDate,
      description: "not found",
    },
    accounts: [],
    accountsForList2: [],
    wallet: [],
    errors: {},
    formSubmitted: false,
    loading: false,
  });
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();
  let { Id } = useParams();
  const navigate = useNavigate();
  const { token } = Token();
  const modelDataService = new dataService(token, "correspond");
  const modelDataServiceAccount = new dataService(token, "account");
  const [wallets, setWallets] = useState([]);
  useEffect(async () => {
    let result = await modelDataServiceAccount.getAll({});
    setState({
      ...state,
      accounts: result.data.data,
    });
  }, []);
  function handleChangeAccount(e) {
    var walletsState = [];
    if (e.target.value > 0)
      state.accounts.forEach((element) => {
        if (element.id == e.target.value) {
          setWallets(element.wallet);
        }
      });
  }
  function handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let { formData } = state;
    formData[name] = value;
    setState({
      ...state,
      formData: formData,
    });
  }
  function handleDateChange(date) {
    setState({
      ...state,
      formData: {
        ...state.formData,
        date: moment(date).format("YYYY/MM/DD hh:mm:ss"),
      },
    });
    setStartDate(date);
  }
  const validateLoginForm = (e) => {
    let errors = {};
    const { formData } = state;

    //username
    if (isEmpty(formData.accountId)) {
      errors.accountId = t("accountId can't be blank");
    }
    // if (isEmpty(formData.date)) {
    //     errors.date = translate.ar.forms["date can't be blank"];
    // }
    if (formData.accountId <= 0) {
      errors.accountId = t("accountId is false");
    }
    if (isEmpty(errors)) {
      return true;
    } else {
      console.log(errors);
      return errors;
    }
  };
  async function handleSubmit(event) {
    event.preventDefault();
    let errors = validateLoginForm();
    if (errors === true) {
      save();
    } else {
      for (const [key, value] of Object.entries(errors)) {
        console.log(value);
        NotificationManager.error(value, "validation");
      }
      setState({
        ...state,
        errors: errors,
        formSubmitted: true,
      });
    }
  }
  async function save() {
    const { formData } = state;
    modelDataService
      .create(formData)
      .then((response) => {
        if (response.data.success) {
          NotificationManager.success(
            response.data.message,
            t("create motabeghe")
          );
          navigate("/accounting");
        } else {
          setState({
            ...state,
            errors: response.data,
            formSubmitted: true,
          });
        }
      })
      .catch((e) => {
        NotificationManager.error(e.message, t("customer create fail."));
      });
  }
  let dataBradCrumb = [
    {
      link: "/",
      name: t("home"),
    },
    {
      link: "/correspond",
      name: t("قائمة"),
    },
    {
      link: "/correspond/create",
      name: t("انشاء"),
    },
  ];
  return (
    <div className="form-row  card-body">
      <Breadcrumb data={dataBradCrumb} />

      <div className="col-md-12">
        <form className="form card" id="user-form">
          <h5>{t("مطابقه")}</h5>
          <div className="form-row">
            {state.errors.result && (
              <div className="alert alert-danger" role="alert">
                {state.errors.result}
              </div>
            )}
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <div className="col-md-4">
                <label htmlFor="exampleInputEmail1">{t("انتخاب الحساب")}</label>
              </div>

              <div className="col-md-12 form-group">
                <select
                  className="form-control silver"
                  name="accountId"
                  onChange={(el) => {
                    handleChangeAccount(el);
                    handleChange(el);
                  }}
                >
                  <option value="0">{t("choose")}</option>
                  {state.accounts.map((item) => {
                    if (account && item.id === account.id) {
                      return (
                        <option value={item.id} selected="selected">
                          {item.name}
                        </option>
                      );
                    } else {
                      return <option value={item.id}>{item.name}</option>;
                    }
                  })}
                </select>
                {/*{props.errors && props.errors.subaccount_id &&*/}
                {/*    <FormText className="text-white">{props.errors.subaccount_id}</FormText>*/}
                {/*}*/}
              </div>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="date">{t("تاریخ الوصل")}</label>
              <DatePicker
                name="date"
                id="date"
                className="form-control silver"
                dateFormat="yyyy/MM/dd"
                selected={startDate}
                strictParsing
                closeOnScroll={true}
                disabled
                onChange={(date) => handleDateChange(date)}
              />
              {state.errors.date && <FormText>{state.errors.date}</FormText>}
            </div>

            <div className="col-md-6">
              <ul className="list-group row">
                {wallets.map((item) => {
                  return (
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      {item.currency_name}
                      <span className="badge badge-primary badge-pill">
                        {item.credit}
                      </span>
                    </li>
                  );
                })}
              </ul>

              <br />
              <br />
              <br />
            </div>

            <div className="col-md-12 form-group">
              <label htmlFor="exampleInputEmail1">{t("الملاحظات")}</label>
              <textarea
                className="inputStyle form-control "
                rows="5"
                name="description"
                onChange={(date) => handleChange(date)}
              ></textarea>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="buttons center">
                <Button
                  handleClick={handleSubmit}
                  type="submit"
                  className="green"
                >
                  <span className="icon-Save" />
                  {t("تسجیل")}
                </Button>
                <Button handleClick={() => {}} className="blue">
                  <span className="icon-Cancel" />
                  {t("الغاء")}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

// export default CorrespondForm
const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setAccount: () =>
      dispatch({
        type: "SET_ACCOUNT",
      }),
    clearAccount: () =>
      dispatch({
        type: "CLEAR_ACCOUNT",
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CorrespondForm);
