import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";

function DeleteConfirmationModal(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = () => {
    handleClose();
    props.dataService
      .delete(props.model.id)
      .then(() => {
        NotificationManager.success(
          t("item delete successfully"),
          t("delete item")
        );
        props.fetchEntities();
      })
      .catch((error) => {
        NotificationManager.error(t("error"), t(""));
        console.log(error);
      });
    return true;
    
  };

  return (
    <>
      <span className={props.item.icon} onClick={handleShow} />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("delete")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Are you sure you want to delete this item?")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("الغاء")}
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteConfirmationModal;
