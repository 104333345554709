import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { BaseUrl } from "./../../utilities/StaticProvider";
import Alert from "./../../utilities/Alert/AlertMessages";
import { useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Breadcrumb from "../Breadcrumb";
import FormGroup from "../formGroup/formGroup";
import Button from "../button/Button";
import translate from "../../message/avii";
import { useTranslation } from "react-i18next";
const CreateSubAccount = () => {
  const { t, i18n } = useTranslation();
  const [account, setAccount] = useState([]);
  const [subAccount, setSubAccount] = useState([]);
  const [defaultValueOfSelect2, setDefaultValueOfSelect2] = useState([]);
  let { Id } = useParams();
  useEffect(async () => {
    if (Id) {
      let result = await axios.get(BaseUrl + "subaccount/" + Id);
      setSubAccount(result.data.data);
      setDefaultValueOfSelect2({
        value: result.data.data.account.id,
        label: result.data.data.account.name,
      });
    }
    const techCompanies = [];
    axios
      .get(BaseUrl + "account")
      .then((result) => {
        result.data.data.forEach((item) => {
          techCompanies.push({
            label: item.name + " " + item.nationalCode,
            value: item.id,
          });
        });
        setAccount(techCompanies);
      })
      .catch((err) => {
        console.log(err);
        console.log("error to load account");
      });
  }, []);
  let name = null;
  let mobile = null;
  let description = null;
  let percentage = null;
  let createOrUpdate = (e) => {
    e.preventDefault();
    let data = {
      name: name.value,
      mobile: mobile.value,
      creatorId: 1,
      description: description.value,
      account_id: e.target.accountId.value,
      percentage: percentage.value,
      status: 1,
    };
    let oprions = {
      method: Id ? "put" : "post",
      url: Id ? BaseUrl + "subaccount/" + Id : BaseUrl + "subaccount",
      data: data,
    };
    let title;
    let message = "";
    if (Id) {
      message = t("Updated successfully");
      title = t("update");
    } else {
      message = t("created successfully");
      title = t("create");
    }
    axios(oprions).then(
      (response) => {
        NotificationManager.success(message, title);
      },
      (error) => {
        NotificationManager.error(t("error"), title);
      }
    );
  };
  const breadcrumb = [
    {
      link: "/",
      name: t("home"),
    },
    {
      link: "/setting",
      name: t("اعدادات"),
    },
    {
      link: "/account/sub/",
      name: t("قائمة"),
    },
    {
      link: "/account/sub/create",
      name: t("انشاء"),
    },
  ];
  return (
    <div>
      <Breadcrumb data={breadcrumb} />
      <div className="form-row  card-body">
        <div className="col-md-12">
          <form className="form card" id="user-form" onSubmit={createOrUpdate}>
            <h5>{t("تعریف الحساب الفرعی")}</h5>
            <div className="form-row">
              {/*{state.errors.result &&*/}
              {/*    <div className="alert alert-danger" role="alert">*/}
              {/*        {state.errors.result}*/}
              {/*    </div>*/}
              {/*}*/}
            </div>

            <div className="row  center">
              <div className="col-md-8">
                <div className="col-md-12 form-group">
                  <label htmlFor="exampleInputEmail1">{t("اسم الحساب")}</label>
                  <input
                    ref={(el) => (name = el)}
                    type="text"
                    className="inputStyle form-control "
                    id="exampleInput"
                    aria-describedby="nationalCode"
                    defaultValue={subAccount.name}
                  />
                </div>
                <div className="col-md-12 form-group">
                  <label htmlFor="exampleInputEmail1">{t("موبایل")}</label>
                  <input
                    ref={(el) => (mobile = el)}
                    type="number"
                    className="inputStyle form-control "
                    id="exampleInput"
                    aria-describedby="mobile"
                    defaultValue={subAccount.mobile}
                  />
                </div>
                <div className="col-md-12 form-group">
                  <div className="col-md-12">
                    <label htmlFor="exampleInputEmail1">{t("درصد")}</label>
                    <input
                      ref={(el) => (percentage = el)}
                      type="number"
                      className="inputStyle form-control "
                      id="exampleInput"
                      aria-describedby="percentage"
                      defaultValue={subAccount.percentage}
                    />
                  </div>
                </div>
                <div className="col-md-12 form-group">
                  <div className="col-md-12 ">
                    <label htmlFor="exampleInputEmail1">
                      {t("انتخاب الحساب")}
                    </label>
                    <Select
                      placeholder={"اختيار"}
                      name={"accountId"}
                      options={account}
                      isSearchable
                    />
                  </div>
                </div>
                <div className="col-md-12 form-group">
                  <label htmlFor="exampleInputEmail1">{t("التفاضیل")}</label>
                  <textarea
                    ref={(el) => (description = el)}
                    defaultValue={subAccount.description}
                    className="inputStyle form-control "
                  ></textarea>
                </div>

                <div className="col-md-6 form-group">
                  <div className="col-md-4">
                    <label htmlFor="exampleInputEmail1">{t("الفعال")}</label>
                    <input name="status" type="checkbox" value="1" />
                  </div>
                </div>
                <div className="form-group col-md-12 ">
                  <button
                    type="submit"
                    id="submit2"
                    className="btn btn-sm btn-success margin30"
                  >
                    {t("تسجیل")}
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary">
                    {t("الغاء")}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CreateSubAccount;
