import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncCreatableSelect from "react-select/async-creatable";
import Token from "../auth/Token";
import dataService from "../../services/data.service";
import { NotificationManager } from "react-notifications";

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: state.isFocused ? "#fff" : "#e8e8e8",
    borderRadius: "8px",
    borderColor: "#e8e8e8",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "red" : "blue",
    },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
};

function AsyncSelectWithAdd(props) {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(props.value);

  const { token } = Token();
  const ds = new dataService(token, props.model);

  const loadOptions = (inputValue, callback) => {
    setInputValue(inputValue);
    if (inputValue.length > 2) {
      fetchItems(callback);
    }
  };

  function fetchItems(callback) {
    setIsLoading(true);
    ds.getAll({
      q: inputValue,
      per_page: 5,
    })
      .then((response) => {
        
        let items = [];
        response.data.data.map((entity) => {
          items.push({
            label: entity.name ? entity.name : "",
            value: entity.id,
          });
        });
        setOptions(items);
        if (callback) {
          callback(items);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        throw error;
      });
  }

  const handleAddItem = async (event) => {
    setIsLoading(true);
    let data = {
      name: inputValue,
      status: 1,
    };
    ds.create(data).then(
      (response) => {
        setIsLoading(false);
        NotificationManager.success(
          t("the transaction model save successfully"),
          t("create")
        );
        const item = {
          label: response.data.data.name,
          value: response.data.data.id,
        };
        setOptions([...options, item]); // add the new option to the list
        fetchItems(null);
      },
      (error) => {
        setIsLoading(false);
        Object.entries(error.response.data.data).forEach((item) => {
          NotificationManager.error(JSON.stringify(item[1][0]));
        });
      }
    );
  };

  const noOptionsMessage = () => (
    <div>
      {t("No options found.")}
      <button type="button" onClick={handleAddItem}>
        {t("create")}
      </button>
    </div>
  );

  const customLoadingMessage = () => {
    return t("loading");
  };
  const formatCreateLabel = (inputValue) => {
    return t("addOptionToOptions", { inputValue });
  };

  const handleMenuOpen = () => {
    if (options.length === 0) {
      fetchItems(null);
    }
  };

  return (
    <AsyncCreatableSelect
      loadOptions={loadOptions}
      defaultOptions={options}
      options={options}
      value={selectedOption}
      name={props.name}
      styles={customStyles}
      className="minimal"
      onMenuOpen={handleMenuOpen}
      isClearable
      isLoading={isLoading}
      onChange={(e) => {
        setSelectedOption(e);
        props.onChange(e);
        setInputValue("");
      }}
      onCreateOption={handleAddItem}
      allowCreateWhileLoading={false}
      loadingMessage={customLoadingMessage}
      formatCreateLabel={formatCreateLabel}
    />
  );
}

export default AsyncSelectWithAdd;
