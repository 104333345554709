import React, { useEffect, useState } from "react";
import Datatable from "../dataTable/DataTable";
import dataService from "../../services/data.service";
import Token from "../auth/Token";
import Breadcrumb from "../Breadcrumb";
import { Navigate } from "react-router-dom";
import TaslimDataTable from "./TaslimDataTable";
import moment from "moment";
import { useTranslation } from "react-i18next";
const Taslim = (props) => {
  const { t, i18n } = useTranslation();
  const config = {};
  const columns = [
    {
      key: "id",
      title: t("الترتیب"),
      value: function (model) {
        return model.id;
      },
    },
    {
      key: "customer_rec",
      title: t("المستفیذ"),
      value: function (model) {
        return model?.in?.customer?.name;
      },
    },
    {
      key: "customer_rec_name",
      title: t("حساب المستلم"),
      value: function (model) {
        return model?.in?.account?.name;
      },
    },
    {
      key: "price",
      title: t("مبلغ الحواله"),
      value: function (model) {
        return model.price;
      },
    },
    {
      key: "customer_snd_name",
      title: t("حساب الدافع"),
      value: function (model) {
        return model?.out?.account?.name;
      },
    },
    {
      key: "customer_snd",
      title: t("المرسل"),
      value: function (model) {
        return model?.out?.customer?.name;
      },
    },
    {
      key: "date",
      title: t("تاریخ الحواله"),
      value: function (model) {
        return moment(model.date).format("YYYY/MM/DD");
      },
    },
    {
      key: "currency",
      title: t("نوع العمل"),
      value: function (model) {
        return model?.currency?.name;
      },
    },
    {
      key: "number",
      title: t("رقم"),
      value: function (model) {
        return model.number;
      },
    },
  ];
  const actions = [];
  const params = ["status=0"];
  const { token } = Token();
  const ds = new dataService(token, "cheque");
  const breadcrumb = [
    {
      link: "/",
      name: t("home"),
    },
    {
      link: "/taslim",
      name: t("تسلیم الحوالات"),
    },
  ];
  const onDoubleClick = async (id) => {
    ds.get(id)
      .then((response) => {
        return <Navigate to={"/cheque-out/" + id} />;
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <div className="col-md-12">
      <Breadcrumb data={breadcrumb} />
      <TaslimDataTable
        entity="users"
        params={params}
        dataService={ds}
        columns={columns}
        onDoubleClick={onDoubleClick}
        actions={actions}
        config={config}
      />
    </div>
  );
};
export default Taslim;
