import React, { useState } from "react";
import Token from "../auth/Token";
import dataService from "../../services/data.service";
import { Button, Modal } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import DatePicker from "react-datepicker";
import Dropzone from "react-dropzone-uploader";
import moment from "moment";
import Utilities from "../../utilities/utilities";
import SignatureCanvas from "react-signature-canvas";
import { useTranslation } from "react-i18next";
import AsyncSelectWithAdd from "../async/AsyncSelectWithAdd";
const TaslimModal = (props) => {
  const { t } = useTranslation();
  const { token } = Token();
  const ds = new dataService(token, "cheque/taslim");
  const [startDate, setStartDate] = useState(new Date());
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [taslim_date, setTaslimDate] = useState(
    moment(startDate).format("YYYY/MM/DD hh:mm:ss")
  );
  const [imageFile, setImageFile] = useState("");
  const [signature, setSignature] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleChangeStatus = async ({ meta, remove }, status, files) => {
    if (status === "done") {
      let image = await Utilities.toBase64(files[0].file);
      setImageFile(image);
    }
  };
  var sigPad = {};
  const clear = () => {
    if (sigPad) sigPad.clear();
  };
  const trim = () => {
    setSignature(sigPad.getTrimmedCanvas().toDataURL("image/png"));
  };
  const handleCustomerChange = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };
  const handleFormSubmit = async (e) => {
    trim();
    e.preventDefault();
    var data = {
      customer_snd: selectedCustomer.value,
      date: taslim_date,
      image: imageFile,
      sign: signature,
    };
    ds.update(props.cheque.id, data)
      .then((response) => {
        if (response.data.success)
          NotificationManager.success(response.data.data);
        setSelectedCustomer(null);
        setTaslimDate(moment(startDate).format("YYYY/MM/DD hh:mm:ss"));
        clear();
        if (props.callback) props.callback();
        setShowModal(false);
      })
      .catch((e) => {
        NotificationManager.error(e.message, t("error"));
        setShowModal(false);
      });
  };
  const handleClose = () => {
    setShowModal(false);
  };
  function handleDateChange(date) {
    setTaslimDate(moment(date).format("YYYY/MM/DD hh:mm:ss"));
    setStartDate(date);
  }
  return (
    <div className="d-inline">
      <button className="ml-3 btn btn-info" onClick={() => setShowModal(true)}>
        {t("تسلیم")}
      </button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>{t("تسلیم الحواله")}</Modal.Title>
          <Button
            className="float-start m-0"
            variant="link"
            onClick={handleClose}
          >
            <span className="icon icon-Cancel text-dark" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit} className=" text-right">
            <div className="form-group">
              <div className="title">{t("اسم المستفیذ")} :</div>
              <div className="input">
                <AsyncSelectWithAdd
                  model="customers"
                  name="customer_id"
                  onChange={handleCustomerChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="taslimDate">{t("التاریخ")}</label>
              <DatePicker
                name="taslimDate"
                id="taslimDate"
                className="form-control silver"
                dateFormat="YYYY/MM/DD hh:mm:ss"
                selected={startDate}
                value={taslim_date}
                closeOnScroll={true}
                onChange={(date) => handleDateChange(date)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="image">{t("تصویر")}</label>
              <br />
              <Dropzone
                id="image"
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                autoUpload={false}
                inputContent="تصویر"
                accept="image/*"
                styles={{
                  dropzone: {
                    width: 300,
                    height: 200,
                    textAlign: "center",
                    overflow: "hidden",
                    border: "2px dashed rgb(151, 151, 151)",
                    borderRadius: "8px",
                  },
                  inputLabel: {
                    color: "rgb(151, 151, 151)",
                    fontFamily: "Droid Arabic Kufi",
                    fontSize: 14,
                    fontWeight: 400,
                  },
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="signature">{t("إمضاء")}</label>
              <br />
              <SignatureCanvas
                id="signature"
                penColor="green"
                ref={(ref) => {
                  sigPad = ref;
                }}
                canvasProps={{
                  width: 300,
                  height: 200,
                  className:
                    "sigCanvas border-dashed-custom border-secondary border-5 rounded d-block mx-auto",
                }}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              {t("تسلیم")}
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default TaslimModal;
