import React, { useEffect, useState } from "react";
import dataService from "../../services/data.service";
import Token from "../auth/Token";
import moment from "moment";
import NumberFormat from "react-number-format";
import Datatable from "../dataTable/DataTable";
import ExchangeDataTable from "./ExchangeDataTable";
import { useTranslation } from "react-i18next";
const ExchangeList = (props) => {
  const { t, i18n } = useTranslation();
  const config = {};
  const columns = [
    {
      key: "id",
      title: t("الترتیب"),
      value: function (model) {
        return model.id;
      },
    },
    {
      key: "number",
      title: t("number"),
      value: function (model) {
        return model.number;
      },
    },
    {
      key: "created_at",
      title: t("create date"),
      value: function (model) {
        return moment(model.created_at).format("YYYY/MM/DD");
      },
    },
    {
      key: "sellerAccountId",
      title: t("اسم اشراء"),
      value: function (model) {
        return model.seller?.name;
      },
    },
    {
      key: "sellerCurrency",
      title: t("العمله اشراء"),
      value: function (model) {
        return model.sellerCurrency?.name;
      },
    },
    {
      key: "salesPrice",
      title: t("سعر الشراء"),
      value: function (model) {
        return (
          <NumberFormat
            value={model.salesPrice}
            displayType={"text"}
            thousandSeparator={true}
            prefix={""}
          />
        );
      },
    },
    {
      key: "salesAmount",
      title: t("المبلغ اشراء"),
      value: function (model) {
        return (
          <NumberFormat
            value={model.salesAmount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={""}
          />
        );
      },
    },
    {
      key: "buyerAccountId",
      title: t("اسم البائع"),
      value: function (model) {
        return model.buyer?.name;
      },
    },
    {
      key: "buyerCurrency",
      title: t("العمله البائع"),
      value: function (model) {
        return model.buyerCurrency?.name;
      },
    },
    {
      key: "buyerPrice",
      title: t("سعر البیع"),
      value: function (model) {
        return (
          <NumberFormat
            value={model.buyerPrice}
            displayType={"text"}
            thousandSeparator={true}
            prefix={""}
          />
        );
      },
    },
    {
      key: "buyerAmount",
      title: t("المبلغ البیع"),
      value: function (model) {
        return (
          <NumberFormat
            value={model.buyerAmount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={""}
          />
        );
      },
    },
    {
      key: "description",
      title: t("description"),
      value: function (model) {
        return model.description;
      },
    },
  ];
  const actions = [];
  const { token } = Token();
  const ds = new dataService(token, "currencyExchange");
  return (
    <div class="col-md-12">
      <ExchangeDataTable
        entity="currencyExchange"
        dataService={ds}
        columns={columns}
        actions={actions}
        config={config}
      />
    </div>
  );
};
export default ExchangeList;
