import React, { useEffect, useState } from "react";
import Datatable from "../dataTable/DataTable";
import dataService from "../../services/data.service";
import Token from "../auth/Token";
import Parser from "html-react-parser";
import moment from "moment";
import AccountingDataTable from "../dataTable/AccountingDataTable";
import Breadcrumb from "../Breadcrumb";
import TransferDataTable from "./transferDataTable";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";
const TransferList = (props) => {
  const { t, i18n } = useTranslation();
  const config = {
    export: {
      link: "./export",
      icon: "icon-Save",
      title: "",
    },
  };
  const columns = [
    {
      key: "id",
      title: t("الترتیب"),
      value: function (model) {
        return model.id;
      },
    },
    {
      key: "number",
      title: t("رقم الوصل"),
      value: function (model) {
        return model.number;
      },
    },
    {
      key: "date",
      title: t("تاریخ الوصل"),
      value: function (model) {
        return moment(model.date).format("YYYY/MM/DD");
      },
    },
    {
      key: "currency_id",
      title: t("نوع العمل"),
      value: function (model) {
        return model?.currency?.name;
      },
    },
    {
      key: "price",
      title: t("مبلغ الوصل"),
      value: function (model) {
        return (
          <NumberFormat
            value={model.price}
            displayType={"text"}
            thousandSeparator={true}
            prefix={""}
          />
        );
      },
    },
    {
      key: "deposit_account_name",
      title: t("اسم له"),
      value: function (model) {
        return model?.deposit?.account?.name;
      },
    },
    // {
    //     key: 'deposit_subaccount_name',
    //     title: 'له فرع الحساب',
    //     value: function (model) {
    //         return model?.deposit?.subaccount?.name;
    //     }
    // },
    {
      key: "withdraw_account_name",
      title: t("اسم لنا"),
      value: function (model) {
        return model?.withdraw?.account?.name;
      },
    },
    // {
    //     key: 'withdraw_subaccount_name',
    //     title: 'لنا فرع الحساب',
    //     value: function (model) {
    //         return model?.withdraw?.subaccount?.name;
    //     }
    // },
    {
      key: "description",
      title: t("الملاحظات"),
      value: function (model) {
        return model?.description;
      },
    },
    {
      key: "user",
      title: t("المستخدم"),
      value: function (model) {
        return model?.username;
      },
    },
  ];
  const actions = [];
  const { token } = Token();
  const ds = new dataService(token, "accounting");
  const breadcrumb = [
    {
      link: "/",
      name: t("home"),
    },
    {
      link:
        props.type === "deposit"
          ? "/transfer/list/deposit"
          : "/transfer/list/withdraw",
      name:
        props.type === "deposit"
          ? t("قائمة المصروف له")
          : t("قائمة المصروف لنا"),
    },
  ];
  return (
    <>
      <Breadcrumb data={breadcrumb} />
      <TransferDataTable
        entity="transaction"
        type={props.type}
        dataService={ds}
        columns={columns}
        actions={actions}
        config={config}
      />
    </>
  );
};
export default TransferList;
