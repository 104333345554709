import React, { useEffect, useMemo, useState } from "react";
import { Axios as axios } from "axios";
import { Link } from "react-router-dom";
import da from "moment/dist/locale/da";
import { ShimmerTable } from "react-shimmer-effects";
import Parser from "html-react-parser";
import { toJSON } from "moment/src/lib/moment/to-type";
import "./dataTable.css";
import { useSelector } from "react-redux";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import AsyncSelect from "react-select/async";
import { forEach } from "react-bootstrap/ElementChildren";
import { useTranslation } from "react-i18next";
import DeleteConfirmationModal from "../modals/DeleteConfirmationModal";
import dataService from "../../services/data.service";

export default function Datatable(props) {
  const { t } = useTranslation();
  const account = useSelector((state) => state.account);
  const [state, setState] = useState({
    entities: {
      data: [],
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 20,
        to: 1,
        total: 1,
      },
    },
    first_page: 1,
    current_page: 1,
    sorted_column: props.columns[0].key,
    offset: 4,
    order: "desc",
    filters: {
      options: [],
      title: props.title,
    },
    q: "",
    loader: true,
    cancelBtn: false,
  });

  function fetchEntities(callback) {
    return new Promise((resolve, reject) => {
      setState({
        ...state,
        loader: true,
      });
      return props.dataService
        .getAll({
          page: state.current_page,
          column: state.sorted_column,
          order: state.order,
          q: state.q,
          params: props.params,
          per_page: state.entities.meta.per_page,
        })
        .then((response) => {
          let options = [];
          if (callback && callback.action !== "select-option") {
            state.filters.options = [];
          }
          let data = response.data.data.map((entity) => {
            options.push({
              label: entity.name ? entity.name : "",
              value: entity.id,
            });
            return props.columns
              .map((item) => item.key)
              .reduce(
                (a, e) => (
                  (a[e] = props.columns
                    .find((item) => item.key === e)
                    .value(entity)),
                  a
                ),
                {}
              );
          });
          setState({
            ...state,
            entities: {
              ...state.entities,
              data: data,
              meta: response.data.meta,
            },
            filters: {
              ...state.filters,
              options: options,
            },
            loader: false,
          });
          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    });
  }

  /**
   *
   * @param inputValue
   * @param callback
   */
  function fetchAutoComplete(inputValue, callback) {
    new Promise((resolve, reject) => {
      return props.dataService
        .getAll({
          q: inputValue,
          per_page: 5,
        })
        .then((response) => {
          let options = [];
          let data = response.data.data.map((entity) => {
            options.push({
              label: entity.name ? entity.name : "",
              value: entity.id,
            });
          });
          setState({
            ...state,
            filters: {
              options: options,
            },
          });
          if (callback) {
            callback(options);
          }
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    });
  }
  function changePage(pageNumber) {
    state.current_page = pageNumber;
    fetchEntities();
  }
  function columnHead(value) {
    return value.split("_").join(" ").toUpperCase();
  }
  function pagesNumbers() {
    if (!state.entities.meta.to) {
      return [];
    }
    let from = state.entities.meta.current_page - state.offset;
    if (from < 1) {
      from = 1;
    }
    let to = from + state.offset * 2;
    if (to >= state.entities.meta.last_page) {
      to = state.entities.meta.last_page;
    }
    let pagesArray = [];
    for (let page = from; page <= to; page++) {
      pagesArray.push(page);
    }
    return pagesArray;
  }
  useEffect(() => {
    fetchEntities().then((result) => {
      if (props.onSelect) {
        !account && result[0] && props.onSelect(result[0].id);
        account && props.onSelect(account.id);
      }
    });
  }, []);
  function tableHeads() {
    let icon;
    if (state.order === "asc") {
      icon = <i className="fas fa-arrow-up" />;
    } else {
      icon = <i className="fas fa-arrow-down" />;
    }
    return props.columns.map((column) => {
      return (
        <th
          className={column.hidden ? "table-head" : "table-head"}
          key={column.key}
          onClick={() => sortByColumn(column.key)}
        >
          {columnHead(column.title)}
          {column.key === state.sorted_column && icon}
        </th>
      );
    });
  }
  const changeQuery = (inputValue, callback) => {
    if (inputValue.length > 2) {
      state.q = inputValue;
      state.current_page = 1;
      fetchAutoComplete(inputValue, callback);
    }
  };
  const selectOption = (value, callback) => {
    if (callback && callback.action === "select-option") {
      state.q = value.label;
      state.current_page = 1;
      state.cancelBtn = true;
      fetchEntities();
    }
  };
  function filter(event) {
    event.preventDefault();
    state.cancelBtn = true;
    fetchEntities();
  }
  function reset() {
    state.q = "";
    state.cancelBtn = false;
    fetchEntities();
  }
  function list() {
    if (state.entities.data.length) {
      return state.entities.data.map((model) => {
        return (
          <tr
            key={model.id}
            className={account && account.id === model.id ? "active" : ""}
            onClick={(event) => props.onSelect && props.onSelect(model.id)}
            onDoubleClick={(event) => props.onDoubleClick(model.id)}
          >
            {Object.keys(model).map((key) => (
              <td key={key}>{model[key]}</td>
            ))}
            <td className="actions">
              {props.actions.map((item) => {
                let link = item.link.replace(/{id}/i, model.id);
                if (item.type === "link") {
                  return (
                    <Link to={link} title={item.title}>
                      <span className={item.icon} />
                    </Link>
                  );
                } else {
                  return (
                    <DeleteConfirmationModal
                      model={model}
                      item={item}
                      dataService={props.dataService}
                      fetchEntities={fetchEntities}
                    />
                  );
                }
              })}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={props.columns.length} className="text-center">
            {t("No Records Found.")}
          </td>
        </tr>
      );
    }
  }
  function sortByColumn(column) {
    if (column === state.sorted_column) {
      state.order === "asc"
        ? setState(
            {
              ...state,
              order: "desc",
              current_page: state.first_page,
            },
            () => {
              fetchEntities();
            }
          )
        : setState(
            {
              ...state,
              order: "asc",
            },
            () => {
              fetchEntities();
            }
          );
    } else {
      setState(
        {
          ...state,
          sorted_column: column,
          order: "asc",
          current_page: state.first_page,
        },
        () => {
          fetchEntities();
        }
      );
    }
  }
  function handlePagination() {
    let output = [];
    let current =
      state.entities.meta.current_page > 3
        ? state.entities.meta.current_page - 3
        : 0;
    for (let i = current; i < state.entities.meta.last_page; i++) {
      if (i === current + 5) {
        // output.push(<button className='index'>...</button>);
        // output.push(<button onClick={() => changePage(state.entities.meta.last_page - 1)}
        //                     className={state.entities.meta.current_page === state.entities.meta.last_page - 1 ? 'active index' : 'index'}>{state.entities.meta.last_page - 1}</button>);
        // output.push(<button onClick={() => changePage(state.entities.meta.last_page)}
        //                     className={state.entities.meta.current_page === state.entities.meta.last_page ? 'active index' : 'index'}>{state.entities.meta.last_page}</button>);
        break;
      }
      output.push(
        <button
          onClick={() => changePage(i + 1)}
          className={
            state.entities.meta.current_page === i + 1
              ? "active index"
              : "index"
          }
        >
          {i + 1}
        </button>
      );
    }
    return output;
  }
  function renderPagination() {
    return (
      <div className="col-md-12">
        {state.entities.data && state.entities.data.length > 0 && (
          <nav>
            <div className="customer-pagination pagination">
              <button
                disabled={1 === state.entities.meta.current_page}
                onClick={() => {
                  changePage(state.entities.meta.current_page - 1);
                }}
                className={
                  1 === state.entities.meta.current_page
                    ? "next"
                    : "next active"
                }
              >
                <span className="icon icon-Arrow pink reverse" />
              </button>

              <div className="indexes">
                {handlePagination()}

                {/*{Array(state.entities.meta.last_page <= 5 ? state.entities.meta.last_page : 5).fill(0).map((item, index) => {*/}
                {/*    index = index + state.entities.meta.current_page - 1;*/}
                {/*    return <button onClick={() => changePage(index + 1)}*/}
                {/*                   className={state.entities.meta.current_page === index + 1 ? 'index active' : 'index'}>{index + 1}</button>*/}
                {/*})}*/}
              </div>

              <button
                disabled={
                  state.entities.meta.last_page ===
                  state.entities.meta.current_page
                }
                onClick={() => {
                  changePage(state.entities.meta.current_page + 1);
                }}
                className={
                  state.entities.meta.last_page ===
                  state.entities.meta.current_page
                    ? "prev"
                    : "prev active"
                }
              >
                <span className="icon icon-Arrow" />
              </button>
            </div>
          </nav>
        )}
      </div>
    );
  }
  function renderFilters() {
    const customStyles = {
      control: (base, state) => ({
        ...base,
        background: state.isFocused ? "#fff" : "#e8e8e8",
        borderRadius: "8px",
        borderColor: "#e8e8e8",
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
          borderColor: state.isFocused ? "red" : "blue",
        },
      }),
      menu: (base) => ({
        ...base,
        borderRadius: 0,
        marginTop: 0,
      }),
      menuList: (base) => ({
        ...base,
        padding: 0,
      }),
    };
    return (
      <div className="col-md-12">
        <form className="datatable-filterbox" onSubmit={filter}>
          <div className="title">{props.title} :</div>
          <div className="input">
            <AsyncSelect
              value={state.q}
              defaultOptions={state.filters.options}
              loadOptions={changeQuery}
              onChange={selectOption}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  document.getElementById("submitBtn").focus();
                  filter(event);
                }
              }}
            />
            {/*<Select*/}
            {/*    name="name"*/}
            {/*    id="name"*/}
            {/*    placeholder={state.filters.title}*/}
            {/*    className="silver"*/}
            {/*    options={state.filters.options}*/}
            {/*    onChange={event => changeQuery(event)}*/}
            {/*    styles={customStyles}*/}
            {/*/>*/}
          </div>
          <div className="button">
            <button id="submitBtn" className="mini-button" type="submit">
              <span className="icon-Search" />
            </button>
            {state.cancelBtn && (
              <button
                id="cancelBtn"
                className="mini-button"
                type="button"
                onClick={reset}
              >
                <span className="icon-Cancel" />
              </button>
            )}
          </div>
        </form>
      </div>
    );
  }
  function render() {
    return (
      <div className="col-md-12">
        <div className="datatable">
          {props.config.create && (
            <Link to={props.config.create.link} className="datatable-header">
              <button className="btn">
                <span className={props.config.create.icon} />
              </button>
              <h5> {props.config.create.title}</h5>
            </Link>
          )}
          {state.loader && <ShimmerTable row={4} />}
          {!state.loader && (
            <table className={props.tableClass ? props.tableClass : "table"}>
              <thead>
                <tr>{tableHeads()}</tr>
              </thead>
              <tbody>{list()}</tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="form-row">
      {renderFilters()}
      {render()}
      {renderPagination()}
    </div>
  );
}
