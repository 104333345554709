import httpCommon from "../helpers/http-common";
import axios from "axios";
import Token from "../components/auth/Token";
import { preParsePostFormat } from "moment/src/lib/locale/pre-post-format";
import { BaseUrl } from "../utilities/StaticProvider";
import { stringify } from "uuid";
import qs from "qs";
class dataService {
  constructor(token, model) {
    this.model = model;
    this.http = axios.create({
      baseURL: BaseUrl,
      // headers: {
      //     "Content-type": "application/json",
      //     'Authorization': "Bearer " + token
      // }
    });
  }

  init(token) {}
  getAll(options) {
    let params = options.params ? options.params : [];
    if (options.all) {
      params.push(`all=${options.all}`);
    }
    if (options.from) {
      params.push(`from=${options.from}`);
    }
    if (options.to) {
      params.push(`to=${options.to}`);
    }
    if (options.customer1) {
      params.push(`customer1=${options.customer1}`);
    }
    if (options.customer2) {
      params.push(`customer2=${options.customer2}`);
    }
    if (options.account1) {
      params.push(`account1=${options.account1}`);
    }
    if (options.account2) {
      params.push(`account2=${options.account2}`);
    }
    if (options.user_id) {
      params.push(`user_id=${options.user_id}`);
    }
    if (options.price) {
      params.push(`price=${options.price}`);
    }
    if (options.type) {
      params.push(`type=${options.type}`);
    }
    if (options.account_id) {
      params.push(`account_id=${options.account_id}`);
    }
    if (options.number) {
      params.push(`number=${options.number}`);
    }
    if (options.reason) {
      params.push(`reason=${options.reason}`);
    }
    if (options.status) {
      params.push(`status=${options.status}`);
    }
    if (options.description) {
      params.push(`description=${options.description}`);
    }
    if (options.deposit_account_id) {
      params.push(`deposit_account_id=${options.deposit_account_id}`);
    }
    if (options.withdraw_account_id) {
      params.push(`withdraw_account_id=${options.withdraw_account_id}`);
    }
    if (options.currency_id) {
      params.push(`currency_id=${options.currency_id}`);
    }
    if (options.page) {
      params.push(`page=${options.page}`);
    }
    if (options.column) {
      params.push(`column=${options.column}`);
    }
    if (options.order) {
      params.push(`order=${options.order}`);
    }
    if (options.per_page) {
      params.push(`per_page=${options.per_page}`);
    }
    if (options.q) {
      params.push(`q=${options.q}`);
    }
    let url = `/${this.model}?${params.join("&")}`;
    return this.http.get(url);
  }
  getAllFiltered(options) {
    let url = `/${this.model}?${qs.stringify(options)}`;
    return this.http.get(url);
  }
  get(id) {
    return this.http.get(`/${this.model}/${id}`);
  }
  create(data) {
    return this.http.post(`/${this.model}`, data);
  }
  update(id, data) {
    return this.http.put(`/${this.model}/${id}`, data);
  }
  delete(id) {
    return this.http.delete(`/${this.model}/${id}`);
  }
  deleteAll() {
    return this.http.delete(`/${this.model}`);
  }
  findByTitle(title) {
    return this.http.get(`/${this.model}?name=${title}`);
  }
}
export default dataService;
