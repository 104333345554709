import React, { useEffect, useState } from "react";
import moment from "moment";
import Token from "../../auth/Token";
import dataService from "../../../services/data.service";
import Datatable from "../../dataTable/DataTable";
import Breadcrumb from "../../Breadcrumb";
import { useTranslation } from "react-i18next";
const CurrenciesList = () => {
  const { t, i18n } = useTranslation();
  const config = {
    create: {
      link: "./create",
      icon: "icon-Plus",
      title: t("تعریف العمله"),
    },
  };
  const columns = [
    {
      key: "id",
      title: t("الترتیب"),
      value: function (model) {
        return model.id;
      },
    },
    {
      key: "name",
      title: t("اسم العمله"),
      value: function (model) {
        return model.name;
      },
    },
    {
      key: "salesPrice",
      title: t("سعر البيع"),
      value: function (model) {
        return model.salesPrice;
      },
    },
    {
      key: "purchasePrice",
      title: t("سعر الشراء"),
      value: function (model) {
        return model.purchasePrice;
      },
    },
    {
      key: "created_at",
      title: t("تاريخ الانشاء"),
      value: function (model) {
        return moment(model.created_at).format("YYYY/MM/DD hh:mm:ss");
      },
    },
    {
      key: "name",
      title: "",
      value: function (model) {
        return "";
      },
    },
  ];
  const actions = [
    {
      link: "/currency/update/{id}",
      icon: "icon icon-Edit",
      type: "link",
    },
    {
      link: "/currency/delete/{id}",
      icon: "icon icon-Cancel",
      type: "delete",
    },
  ];
  const { token } = Token();
  const ds = new dataService(token, "currencies");
  const breadcrumb = [
    {
      link: "/",
      name: t("home"),
    },
    {
      link: "/setting",
      name: t("اعدادات"),
    },
    {
      link: "/currency",
      name: t("قائمة"),
    },
  ];
  return (
    <div>
      <Breadcrumb data={breadcrumb} />
      <Datatable
        entity="users"
        dataService={ds}
        columns={columns}
        actions={actions}
        config={config}
      />
    </div>
  );
};
export default CurrenciesList;
