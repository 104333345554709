import React, { useEffect, useState } from "react";
import "./List.css";
import JardDataTable from "../dataTable/JardDataTable";
import dataService from "../../services/data.service";
import Token from "../auth/Token";
import moment from "moment";
import NumberFormat from "react-number-format";
import JardForm from "./jardFrom";
import { useTranslation } from "react-i18next";
const List = (props) => {
  const { t, i18n } = useTranslation();
  const config = {
    create: {
      link: "./create",
      icon: "icon-Plus",
      title: t("انشاء جرد"),
    },
  };
  const columns = [
    {
      key: "id",
      title: t("الترتیب"),
      value: function (model) {
        return model.id;
      },
    },
    {
      key: "created_at",
      title: t("تاريخ الانشاء"),
      value: function (model) {
        return moment(model.created_at).format("YYYY/MM/DD");
      },
    },
    {
      key: "description",
      title: t("التفاصیل"),
      value: function (model) {
        return model.description;
      },
    },
    {
      key: "total",
      title: t("المجموع"),
      value: function (model) {
        return (
          <NumberFormat
            value={model.total}
            displayType={"text"}
            thousandSeparator={true}
            prefix={""}
          />
        );
      },
    },
  ];
  const actions = [
    {
      link: "/jard/update/{id}",
      icon: "icon icon-Edit",
      type: "link",
    },
    {
      link: "/jard/delete/{id}",
      icon: "icon icon-Cancel",
      type: "delete",
    },
  ];
  const { token } = Token();
  const ds = new dataService(token, "jard");
  return (
    <div class="col-md-12">
      <JardDataTable
        entity="jard"
        dataService={ds}
        columns={columns}
        actions={actions}
        config={config}
      />
    </div>
  );
};
export default List;
