import React, { createContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./menu.css";
import useComponentVisible from "../useComponentVisible";
import connect from "react-redux/es/connect/connect";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const Menu = (props) => {
  const { t, i18n } = useTranslation();
  const { state, setState } = useState({});
  let menu = useSelector((state) => state.menu);
  const ref = useRef(null);
  return (
    <nav id="side-menu" ref={ref} onClick={() => props.toggleMenu(menu)}>
      <a className="menu-toggle" href="#" role="button">
        <i className="icon icon-Menu" />
      </a>
      <ul className="nav nav-pills nav-sidebar flex-column menu-items">
        <li
          className="nav-item menu-item"
          onClick={() => props.toggleMenu(menu)}
        >
          <Link to="/" className="nav-link">
            <i className="icon icon-HomeDashbord" />
            <span>{t("لوحه القیاده")}</span>
          </Link>
        </li>

        <li className="nav-item menu-item menu-item-two-links">
          <Link to="/transfer/deposit" className="nav-link one">
            <i className="icon icon-Almasrof-Leh" />
            <span>{t("المصروف له")}</span>
          </Link>
          <Link to="/transfer/list/deposit" className="nav-link two">
            <span className="badge">
              <i className="icon icon-Aljared" />
            </span>
          </Link>
        </li>

        <li className="nav-item menu-item menu-item-two-links">
          <Link to="/transfer/withdraw" className="nav-link one">
            <i className="icon icon-Lana" />
            <span>{t("المصروف لنا")}</span>
          </Link>
          <Link to="/transfer/list/withdraw" className="nav-link two">
            <span className="badge">
              <i className="icon icon-Aljared" />
            </span>
          </Link>
        </li>
        <li className="nav-item menu-item menu-item-two-links">
          <Link to="/cheque-out" className="nav-link one">
            <i className="icon icon-Ahvalate-SAdere" />
            <span>{t("الحوالة الصادرة")}</span>
          </Link>
          <Link to="/cheque-out/list" className="nav-link two">
            <span className="badge">
              <i className="icon icon-Aljared" />
            </span>
          </Link>
        </li>

        <li className="nav-item menu-item menu-item-two-links">
          <Link to="/cheque-in" className="nav-link one">
            <i className="icon icon-Ahvalate-Varede" />
            <span>{t("الحوالة الواردة")}</span>
          </Link>
          <Link to="/cheque-in/list" className="nav-link two">
            <span className="badge">
              <i className="icon icon-Aljared" />
            </span>
          </Link>
        </li>

        <li className="nav-item menu-item menu-item-two-links">
          <Link to="/exchange/buyer" className="nav-link one">
            <i className="icon icon-Beye-Amalat" />
            <span>{t("بیع العملات")}</span>
          </Link>
          <Link to="/exchange/list/buyer" className="nav-link two">
            <span className="badge">
              <i className="icon icon-Aljared" />
            </span>
          </Link>
        </li>

        <li className="nav-item menu-item menu-item-two-links">
          <Link to="/exchange/seller" className="nav-link one">
            <i className="icon icon-Shera-Amalat" />
            <span>{t("شراء العملات")}</span>
          </Link>
          <Link to="/exchange/list/seller" className="nav-link two">
            <span className="badge">
              <i className="icon icon-Aljared" />
            </span>
          </Link>
        </li>

        <li className="nav-item menu-item">
          <Link to="/correspond" className="nav-link">
            <i className="icon icon-Motabeghat" />
            <span>{t("مطبقه")}</span>
          </Link>
        </li>

        <li className="nav-item menu-item">
          <Link to="/accounting" className="nav-link">
            <i className="icon icon-Kashf-Hesab" />
            <span>{t("کشف الحساب")}</span>
          </Link>
        </li>

        <li className="nav-item menu-item">
          <Link to="/taslim" className="nav-link">
            <i className="icon icon-Taslim-Havalat" />
            <span>{t("تسلیم الحوالات")}</span>
          </Link>
        </li>

        <li className="nav-item menu-item">
          <Link to="/users" className="nav-link">
            <i className="icon icon-Omala" />
            <span>{t("العملاء")}</span>
          </Link>
        </li>

        <li className="nav-item menu-item">
          <Link to="/account" className="nav-link">
            <i className="icon icon-Far-ol-Hesab" />
            <span>{t("حساب")}</span>
          </Link>
        </li>

        <li className="nav-item menu-item menu-item-two-links">
          <Link to="/jard/create" className="nav-link one">
            <i className="icon icon-Aljared" />
            <span>{t("الجرد")}</span>
          </Link>
          <Link to="/jard" className="nav-link two">
            <span className="badge">
              <i className="icon icon-Aljared" />
            </span>
          </Link>
        </li>

        <li className="nav-item menu-item">
          <Link to="/account/sub" className="nav-link">
            <i className="icon icon-Far-ol-Hesab" />
            <span>{t("فرع الحساب")}</span>
          </Link>
        </li>

        <li className="nav-item menu-item">
          <Link to="/fiscalyear" className="nav-link">
            <i className="icon icon-Al-senate-Maleyh" />
            <span>{t("السنة المالية")}</span>
          </Link>
        </li>

        <li className="nav-item menu-item">
          <Link to="/setting" className="nav-link">
            <i className="icon icon-Adadat" />
            <span>{t("اعدادات")}</span>
          </Link>
        </li>

        <li className="nav-item menu-item">
          <Link to="/users" className="nav-link">
            <i className="icon icon-ALnmoskhatol-ehtiyati" />
            <span>{t("النسخ الاحتیاطی")}</span>
          </Link>
        </li>

        <li className="nav-item menu-item">
          <Link
            to="/users"
            className="nav-link"
            onClick={() => {
              localStorage.clear();
              window.location.href = "/";
            }}
          >
            <i className="icon icon-Exit" />
            <span>{t("خروج")}</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};
const mapStateToProps = (state) => {
  return {
    menu: state.menu,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenu: (menu) =>
      dispatch({
        type: "TOGGLE_MENU",
        payload: !menu,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
