import React, { useEffect, useState } from "react";
import axios from "axios";
import { BaseUrl } from "./../../utilities/StaticProvider";
import Alert from "./../../utilities/Alert/AlertMessages";
import { useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Breadcrumb from "../Breadcrumb";
import Select from "react-select";
import translate from "../../message/avii";
import { useTranslation } from "react-i18next";
const Form = () => {
  const { t, i18n } = useTranslation();
  let { Id } = useParams();
  let name = null;
  const [fiscalYear, setFiscalYear] = useState([]);
  let titlePage = t("create fiscalyear");
  useEffect(async () => {
    let data = {};
    if (Id) {
      console.log("Id");
      data = await axios.get(BaseUrl + "fiscalyear/" + Id);
      console.log(data);
      setFiscalYear(data.data.data);
    }
  }, []);
  let createOrUpdate = (e) => {
    e.preventDefault();
    let data = {
      name: name.value,
    };
    let options = {
      method: Id ? "put" : "post",
      url: Id ? BaseUrl + "fiscalyear/" + Id : BaseUrl + "fiscalyear",
      data: data,
    };
    let title;
    let message = "";
    if (Id) {
      message = t("Updated successfully");
      title = t("update");
    } else {
      message = t("created successfully");
      title = t("create");
    }
    axios(options).then(
      (response) => {
        NotificationManager.success(message, title);
      },
      (error) => {
        NotificationManager.error(translate.ar.global["error"], title);
      }
    );
  };
  const breadcrumb = [
    {
      link: "/",
      name: t("home"),
    },
    {
      link: "/setting",
      name: t("اعدادات"),
    },
    {
      link: "/fiscalyear",
      name: t("list"),
    },
    {
      link: Id ? "/fiscalyear/update/" + Id : "/fiscalyear/create",
      name: Id ? t("update") : t("create"),
    },
  ];
  return (
    <div>
      <Breadcrumb data={breadcrumb} />
      <div className="form-row  card-body">
        <div className="col-md-12">
          <form className="form card" id="user-form" onSubmit={createOrUpdate}>
            <h5>{titlePage}</h5>
            <div className="form-row">
              {/*{state.errors.result &&*/}
              {/*    <div className="alert alert-danger" role="alert">*/}
              {/*        {state.errors.result}*/}
              {/*    </div>*/}
              {/*}*/}
            </div>

            <div className="row  center">
              <div className="col-md-8">
                <div className="col-md-12 form-group  ">
                  <label htmlFor="exampleInputEmail1">{t("نام")}</label>
                  <input
                    name="name"
                    ref={(el) => (name = el)}
                    type="text"
                    className="inputStyle form-control "
                    id="exampleInput"
                    aria-describedby="name"
                    defaultValue={fiscalYear.name}
                  />
                </div>
                <div className="col-md-12 form-group justify-content-center align-content-center">
                  <button
                    type="submit"
                    id="submit2"
                    className="btn btn-sm btn-success margin30"
                  >
                    {t("تسجیل")}
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary">
                    {t("الغا")}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Form;
