import React, { useEffect, useState } from "react";
import Datatable from "../dataTable/DataTable";
import dataService from "../../services/data.service";
import Token from "../auth/Token";
import Parser from "html-react-parser";
import moment from "moment";
import AccountingDataTable from "../dataTable/AccountingDataTable";
import Breadcrumb from "../Breadcrumb";
import { useTranslation } from "react-i18next";
const Accounting = (props) => {
  const { t, i18n } = useTranslation();
  const config = {
    export: {
      link: "./export",
      icon: "icon-Save",
      title: "",
    },
  };
  const columns = [
    {
      key: "id",
      title: t("الترتیب"),
      value: function (model) {
        return model.id;
      },
    },
    {
      key: "date",
      title: t("التاریخ/الساعة"),
      value: function (model) {
        return moment(model.created_at).format("YYYY/MM/DD");
      },
    },
    {
      key: "entity_type",
      title: t("النوع"),
      value: function (model) {
        let types = {
          1: t("له"),
          2: t("لنا"),
          3: t("حواله صادره"),
          4: t("حواله وارده"),
          5: t("البیع"),
          6: t("الشرا"),
          7: t("مطبقه"),
        };
        let types_class = {
          1: "danger",
          2: "success",
          3: "info",
          4: "warning",
          5: "danger",
          6: "success",
          7: "info",
        };
        return Parser(
          '<span class="btn btn-' +
            types_class[model.entity_type] +
            '">' +
            types[model.entity_type] +
            "</span>"
        );
      },
    },
    {
      key: "account_withraw",
      title: t("حساب"),
      value: function (model) { return model.account_name }
    },
    {
      key: "description",
      title: t("التفاصیل"),
      value: function (model) {
        const accs =
          parseInt(model.type) === 1
            ? model.account_name + " ← " + model.other
            : model.other + " ← " + model.account_name;
        const other = accs ?? "";
        const description = model.description ?? "";
        return `${other.trim()}${
          other && description ? "-" : ""
        }${description.trim()}`;
      },
    },
    {
      key: "number",
      title: t("الرقم"),
      value: function (model) {
        return model.number;
      },
    },
  ];
  const actions = [];
  const { token } = Token();
  const ds = new dataService(token, "transactions");
  const breadcrumb = [
    {
      link: "/",
      name: t("home"),
    },
    {
      link: "/accounting",
      name: t("کشف الحساب"),
    },
  ];
  return (
    <>
      <Breadcrumb data={breadcrumb} />
      <AccountingDataTable
        entity="transactions"
        dataService={ds}
        columns={columns}
        actions={actions}
        config={config}
      />
    </>
  );
};
export default Accounting;
