import React, { useEffect, useState } from "react";
import "./exchange.css";
import Button from "../button/Button";
import DatePicker from "react-datepicker";
import axios from "axios";
import { BaseUrl } from "../../utilities/StaticProvider";
import AlertWithDetails from "../../utilities/Alert/AlertWithDetails";
import Select from "react-select";
import { FormText } from "react-bootstrap";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { isEmpty } from "../../shared/validator";
import translate from "../../message/avii";
import CurrencyInput from "react-currency-input-field";
import { useSelector } from "react-redux";
import Token from "../auth/Token";
import Breadcrumb from "../Breadcrumb";
import { useNavigate, useParams } from "react-router";
import AsyncSelect from "react-select/async";
import dataService from "../../services/data.service";
import { useTranslation } from "react-i18next";
const Exchange = (props) => {
  const { t, i18n } = useTranslation();
  const account = useSelector((state) => state.account);
  const { getUser } = Token();
  const { token } = Token();
  const ds = new dataService(token, "transactions");
  const navigate = useNavigate();
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: state.isFocused ? "#fff" : "#e8e8e8",
      borderRadius: "8px",
      borderColor: "#e8e8e8",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "red" : "blue",
      },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
  };
  const [startDate, setStartDate] = useState(new Date());
  const [state, setState] = useState({
    formData: {
      number: Math.floor(100000 + Math.random() * (1000000 - 100000)),
      date: moment(startDate).format("YYYY/MM/DD hh:mm:ss"),
      sellerAccountId: null,
      sellerCurrency: "",
      sellerCurrencyName: "not found",
      salesPrice: 0,
      userId: getUser().id,
      salesAmount: 0,
      salesNumber: Math.floor(100000 + Math.random() * (1000000 - 100000)),
      buyerAccountId: null,
      buyerCurrency: "",
      buyerCurrencyName: "not found",
      buyerPrice: 0,
      buyerAmount: 0,
      description: " ",
      creatorId: 1,
      cashDeskBuyer: 0,
      cashDeskSeller: 0,
      buyerNumber: Math.floor(100000 + Math.random() * (1000000 - 100000)),
      sellerSubAccountId: null,
      buyerSubAccountId: null,
      buyerAccountName: "",
      sellerAccountName: "",
    },
    errors: {},
    formSubmitted: false,
    loading: false,
  });
  const [buyyerAccounts, setBuyyerAccounts] = useState([]);
  const [sellerAccounts, setSellerAccounts] = useState([]);
  let { id } = useParams();
  const loadAccounts = async () => {
    const response = await fetch(BaseUrl + "account");
    let accounts = await response.json();
    accounts = accounts.data ? accounts.data : accounts;
  };
  const loadSellerAccounts = async () => {
    const response = (await fetch(BaseUrl + "account")).json();
    response.then((result) => {
      let options = [];
      result.data.map((item) => {
        options.push(item);
      });
      setSellerAccounts(options);
    });
  };
  const loadBuyyerAccounts = async () => {
    const response = (await fetch(BaseUrl + "account")).json();
    response.then((result) => {
      let options = [];
      result.data.map((item) => {
        options.push(item);
      });
      setBuyyerAccounts(options);
    });
  };
  function fetchAutoComplete(inputValue, callback, model, stateSetter) {
    ds.model = model;
    return ds
      .getAll({
        q: inputValue,
        per_page: 5,
      })
      .then((response) => {
        let options = [];
        response.data.data.map((entity) => {
          options.push({
            label: entity.name ? entity.name : "",
            value: entity.id,
          });
        });
        stateSetter(options);
        if (callback) {
          callback(options);
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      })
      .finally(() => {
        ds.model = "exchange";
      });
  }
  function changeBuyyerAccountQuery(inputValue, callback) {
    if (inputValue.length > 2) {
      fetchAutoComplete(inputValue, callback, "accounts", setBuyyerAccounts);
    }
  }
  function changeSellerAccountQuery(inputValue, callback) {
    if (inputValue.length > 2) {
      fetchAutoComplete(inputValue, callback, "accounts", setSellerAccounts);
    }
  }
  function handleDateChange(date) {
    setState({
      ...state,
      formData: {
        ...state.formData,
        date: moment(date).format("YYYY/MM/DD hh:mm:ss"),
      },
    });
    setStartDate(date);
  }
  function handleChange(event) {
    const value = event.value ? event.value : event.target.value;
    const name = event.name ? event.name : event.target.name;
    let { formData } = state;
    formData[name] = value;
    setState({
      ...state,
      formData: formData,
    });
  }
  function handleChangeCashDesk(event) {
    const value = event.value ? event.value : event.target.value;
    const name = event.name ? event.name : event.target.name;
    let { formData } = state;
    if (formData[name] == 0) {
      formData[name] = 1;
    } else {
      formData[name] = 0;
    }
    setState({
      ...state,
      formData: formData,
    });
  }
  function getModel(id) {
    fetch(BaseUrl + "currencyExchange/" + id)
      .then((res) => res.json())
      .then(
        (result) => {
          if (!result.success) {
            let errors = {};
            setState({
              ...state,
              errors: errors,
              formSubmitted: true,
            });
          } else {
            updateFormData(result.data);
          }
        },
        (error) => {
          setState({
            ...state,
            loading: true,
            errors: {
              error,
            },
          });
        }
      );
  }
  function updateFormData(data) {
    return new Promise((resolve, reject) => {
      try {
        let formData = {};
        formData.number = data.number;
        formData.date = data.date;
        formData.buyerAccountId = data.buyerAccountId;
        formData.buyerSubAccountId = data.buyerSubAccountId;
        formData.buyerCurrency = data.buyerCurrency;
        formData.buyerPrice = data.buyerPrice;
        formData.buyerAmount = data.buyerAmount;
        formData.sellerAccountId = data.sellerAccountId;
        formData.sellerSubAccountId = data.sellerSubAccountId;
        formData.sellerCurrency = data.sellerCurrency;
        formData.salesPrice = data.salesPrice;
        formData.salesAmount = data.salesAmount;
        formData.date = data.date;
        formData.price = data.price;
        formData.currency_id = data.currency_id;
        formData.reason = data.reason;
        formData.user_id = data.user_id;
        setState({
          ...state,
          formData: formData,
        });
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }
  useEffect(async () => {
    loadBuyyerAccounts();
    loadSellerAccounts().then((result) => {
      if (id) {
        getModel(id);
      }
    });
  }, []);
  const validateLoginForm = (e) => {
    let errors = {};
    const { formData } = state;
    if (isEmpty(formData.date)) {
      errors.date = t("exchange date can not be empty.");
    }
    if (formData.cashDeskSeller === 1) {
      if (isEmpty(formData.sellerAccountId)) {
        errors.sellerAccountId =
          t("sellerAccountId can't be blank");
      }
      if (isEmpty(formData.sellerCurrency)) {
        errors.sellerCurrency =
          t("sellerCurrency can't be blank");
      }
      if (isEmpty(formData.salesPrice)) {
        errors.salesPrice = t("salesPrice can't be blank");
      }
      if (isEmpty(formData.salesAmount)) {
        errors.salesAmount = t("salesAmount can't be blank");
      }
    }
    if (formData.cashDeskBuyer === 1) {
      if (isEmpty(formData.buyerAccountId)) {
        errors.buyerAccountId =
          t("buyerAccountId can't be blank");
      }
      if (isEmpty(formData.buyerCurrency)) {
        errors.buyerCurrency =
          t("buyerCurrency can't be blank");
      }
      if (isEmpty(formData.buyerPrice)) {
        errors.buyerPrice = t("buyerPrice can't be blank");
      }
      if (isEmpty(formData.buyerAmount)) {
        errors.buyerAmount = t("buyerAmount can't be blank");
      }
    }
    if (isEmpty(errors)) {
      return true;
    } else {
      return errors;
    }
  };
  async function submitExchange(event) {
    event.preventDefault();
    let errors = validateLoginForm();
    if (errors === true) {
      if (id) {
        update();
      } else {
        save();
      }
    } else {
      setState({
        ...state,
        errors: errors,
        formSubmitted: true,
      });
    }
  }
  let save = () => {
    let data = state.formData;
    if (data.sellerSubAccountId <= 0) {
      delete data.sellerSubAccountId;
    }
    if (data.cashDeskBuyer <= 0) {
      delete data.cashDeskBuyer;
    }
    if (data.cashDeskSeller <= 0) {
      delete data.cashDeskSeller;
    }
    if (data.buyerSubAccountId <= 0) {
      delete data.buyerSubAccountId;
    }
    if (data.description === " ") {
      data.description = "the description";
    }
    axios({
      method: "post",
      url: BaseUrl + "currencyExchange",
      data: data,
    }).then(
      (response) => {
        NotificationManager.success(
          t("the transaction model save successfully"),
          t("exchange")
        );
        return navigate("/");
      },
      (error) => {
        NotificationManager.error(
          t("error in save transaction model"),
          t("exchange")
        );
        AlertWithDetails(error.response.data, "alert-danger");
      }
    );
  };
  let update = () => {
    let data = state.formData;
    if (data.sellerSubAccountId <= 0) {
      delete data.sellerSubAccountId;
    }
    if (data.buyerSubAccountId <= 0) {
      delete data.buyerSubAccountId;
    }
    axios({
      method: "put",
      url: BaseUrl + "currencyExchange/" + id,
      data: data,
    }).then(
      (response) => {
        NotificationManager.success(
          t("the transaction model save successfully"),
          t("exchange")
        );
        return navigate("/");
      },
      (error) => {
        NotificationManager.error(
          t("error in save transaction model"),
          t("exchange")
        );
        AlertWithDetails(error.response.data, "alert-danger");
      }
    );
  };
  function updateTotalAmount() {
    let { formData } = state;
    let buyerAmount = 0;
    if (
      selectedBuyerAccount &&
      selectedBuyerCurrency &&
      selectedSellerAccount &&
      selectedSellerCurrency
    ) {
      buyerAmount =
        (state.formData.salesPrice * state.formData.salesAmount) /
        state.formData.buyerPrice;
      buyerAmount = buyerAmount.toFixed(2);
    }
    formData["buyerAmount"] = buyerAmount;
    setState({
      ...state,
      formData: formData,
    });
  }
  function handleChangeAmount(value, name) {
    let { formData } = state;
    formData[name] = value;
    setState({
      ...state,
      formData: formData,
    });
    updateTotalAmount();
  }
  let [selectedSellerAccount, setSelectedSellerAccount] = useState(null);
  let [selectedSellerSubAccount, setSelectedSellerSunAccount] = useState(null);
  let [selectedSellerCurrency, setSelectedSellerCurrency] = useState(null);
  let [selectedBuyerAccount, setSelectedBuyerAccount] = useState(null);
  let [selectedBuyerSubAccount, setSelectedBuyerSunAccount] = useState(null);
  let [selectedBuyerCurrency, setSelectedBuyerCurrency] = useState(null);

  //seller
  function sellerAccountOptions() {
    return sellerAccounts.map((item) => {
      if (account && item.id === account.id) {
        return {
          value: item.id,
          label: item.name,
          selected: "selected",
        };
      }
      return {
        value: item.id,
        label: item.name,
      };
    });
  }
  const sellerSubAccountOption = (item) => ({
    value: item.id,
    label: item.name,
  });
  function sellerSubAccountOptions() {
    if (selectedSellerAccount) {
      let _accounts = sellerAccounts.filter(
        (account) => account.id === selectedSellerAccount.value
      );
      if (_accounts && _accounts.length > 0) {
        let account = _accounts[0];
        let subAccounts = account["sub_account"];
        if (subAccounts && subAccounts.length > 0) {
          return subAccounts.map(sellerSubAccountOption);
        } else {
          return [];
        }
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  const sellerCurrencyOption = (item) => ({
    value: item.id,
    label: item.name,
  });
  function sellerCurrencyOptions() {
    if (selectedSellerAccount) {
      let _accounts = sellerAccounts.filter(
        (account) => account.id === selectedSellerAccount.value
      );
      if (_accounts && _accounts.length > 0) {
        let account = _accounts[0];
        let _wallets = account["wallet"].map((item) => {
          if (item.currency) {
            return {
              id: item.currency.id,
              name: item.currency.name,
            };
          } else {
            return {
              id: 0,
              name: "",
            };
          }
        });
        if (_wallets && _wallets.length > 0) {
          return _wallets.map(sellerCurrencyOption);
        } else {
          return [];
        }
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  function sellerPrice() {
    if (selectedSellerAccount && selectedSellerCurrency) {
      let wallets = sellerAccounts.filter(
        (account) => account.id === selectedSellerAccount.value
      )[0]["wallet"];
      if (wallets && wallets.length > 0) {
        let currencies = wallets.filter(
          (wallet) => wallet.currency.id === selectedSellerCurrency.value
        );
        if (currencies.length > 0) {
          let currency = currencies[0];
          if (currency.currency) {
            let price = currency.currency.purchasePrice;
            state.formData.salesPrice = price;
            return price;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } else {
      return state.formData.salesPrice;
    }
  }

  //buyer

  const buyerAccountOption = (item) => ({
    value: item.id,
    label: item.name,
  });
  function buyerAccountOptions() {
    return buyyerAccounts.map(buyerAccountOption);
  }
  const buyerSubAccountOption = (item) => ({
    value: item.id,
    label: item.name,
  });
  function buyerSubAccountOptions() {
    if (selectedBuyerAccount) {
      let _accounts = buyyerAccounts.filter(
        (account) => account.id === selectedBuyerAccount.value
      );
      if (_accounts && _accounts.length > 0) {
        let account = _accounts[0];
        let subAccounts = account["sub_account"];
        if (subAccounts && subAccounts.length > 0) {
          return subAccounts.map(buyerSubAccountOption);
        } else {
          return [];
        }
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  const buyerCurrencyOption = (item) => ({
    value: item.id,
    label: item.name,
  });
  function buyerCurrencyOptions() {
    if (selectedBuyerAccount) {
      let _accounts = buyyerAccounts.filter(
        (account) => account.id === selectedBuyerAccount.value
      );
      if (_accounts.length > 0) {
        let account = _accounts[0];
        let _wallets = account["wallet"].map((item) => {
          if (item.currency) {
            return {
              id: item.currency.id,
              name: item.currency.name,
            };
          } else {
            return {
              id: 0,
              name: "",
            };
          }
        });
        if (_wallets.length > 0) {
          return _wallets.map(buyerCurrencyOption);
        } else {
          return [];
        }
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  function buyerPrice() {
    if (selectedBuyerAccount && selectedBuyerCurrency) {
      let wallets = buyyerAccounts.filter(
        (account) => account.id === selectedBuyerAccount.value
      )[0]["wallet"];
      if (wallets.length > 0) {
        let currencies = wallets.filter(
          (wallet) => wallet.currency.id === selectedBuyerCurrency.value
        );
        if (currencies.length > 0) {
          let currency = currencies[0];
          if (currency.currency) {
            let price = currency.currency.purchasePrice;
            state.formData.buyerPrice = price;
            return price;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } else {
      return state.formData.buyerPrice;
    }
  }
  function setSellerAccount() {
    if (state.formData.sellerAccountId) {
      let item = sellerAccountOptions().find((row) => {
        return +row.value === +state.formData.sellerAccountId;
      });
      selectedSellerAccount = item;
      return item;
    } else if (+props.situation === 1 && account) {
      let item = sellerAccountOptions().find((row) => {
        return +row.value === +account.id;
      });
      if (item) {
        state.formData.sellerAccountId = item.value;
      }
      selectedSellerAccount = item;
      return item;
    }
  }
  function setSellerCurrency() {
    let item = sellerCurrencyOptions().find((row) => {
      return +row.value === +state.formData.sellerCurrency;
    });
    selectedSellerCurrency = item;
    return item;
  }
  function setBuyerAccount() {
    if (state.formData.buyerAccountId) {
      let item = buyerAccountOptions().find((row) => {
        return +row.value === +state.formData.buyerAccountId;
      });
      selectedBuyerAccount = item;
      return item;
    } else if (+props.situation === 2 && account) {
      let item = buyerAccountOptions().find((row) => {
        return +row.value === +account.id;
      });
      selectedBuyerAccount = item;
      return item;
    }
  }
  function setBuyerCurrency() {
    let item = buyerCurrencyOptions().find((row) => {
      return +row.value === +state.formData.buyerCurrency;
    });
    selectedBuyerCurrency = item;
    return item;
  }
  function renderSeller() {
    return (
      <div className="col-md-4">
        <div className="form-row">
          <div className="card">
            <div className="card-body">
              <div className="form-row">
                <div className="col-md-12 form-group">
                  <label htmlFor="sellerAccountId">{t("اسم البائع")}</label>
                  <AsyncSelect
                    name="sellerAccountId"
                    id="sellerAccountId"
                    className="silver"
                    defaultOptions={sellerAccountOptions()}
                    value={selectedSellerAccount}
                    loadOptions={changeSellerAccountQuery}
                    isClearable
                    onChange={(item) => {
                      if (item) {
                        setSelectedSellerAccount(item);
                        state.formData.sellerAccountId = item.value;
                      }
                    }}
                    styles={customStyles}
                  />
                  {state.errors.sellerAccountId && (
                    <FormText>{state.errors.sellerAccountId}</FormText>
                  )}
                </div>
                <div className="col-md-12 form-group">
                  <label htmlFor="sellerSubAccountId">{t("فرع الحساب")}</label>
                  <Select
                    name="sellerSubAccountId"
                    id="sellerSubAccountId"
                    className="silver"
                    options={sellerSubAccountOptions()}
                    value={sellerSubAccountOptions().find((row) => {
                      return +row.value === +state.formData.sellerSubAccountId;
                    })}
                    isSearchable
                    onChange={(item) => {
                      setSelectedSellerSunAccount(item);
                      state.formData.sellerSubAccountId = item.value;
                    }}
                    styles={customStyles}
                  />
                  {state.errors.sellerSubAccountId && (
                    <FormText>{state.errors.sellerSubAccountId}</FormText>
                  )}
                </div>
                <div className="col-md-12 form-group">
                  <label htmlFor="sellerCurrency">{t("نوع العمله")}</label>
                  <Select
                    name="sellerCurrency"
                    id="sellerCurrency"
                    className="silver"
                    options={sellerCurrencyOptions()}
                    value={setSellerCurrency()}
                    isSearchable
                    onChange={(item) => {
                      setSelectedSellerCurrency(item);
                      state.formData.sellerCurrency = item.value;
                    }}
                    styles={customStyles}
                  />
                  {state.errors.sellerCurrency && (
                    <FormText>{state.errors.sellerCurrency}</FormText>
                  )}
                </div>

                <div className="col-md-12 form-group">
                  <label htmlFor="cashDeskSeller">{t("الصندوق")}</label>

                  <input
                    type="checkbox"
                    name="cashDeskSeller"
                    value="1"
                    onChange={(event) => {
                      handleChangeCashDesk(event);
                    }}
                  />
                </div>
                <div className="col-md-12 form-group">
                  <label htmlFor="name">{t("سعرالبیع")}</label>
                  <CurrencyInput
                    id="salesPrice"
                    name="salesPrice"
                    className="form-control silver"
                    type="search"
                    defaultValue={state.formData.salesPrice}
                    value={sellerPrice()}
                    decimalsLimit={2}
                    onValueChange={(value, name) => {
                      handleChangeAmount(value, name);
                    }}
                  />
                  {state.errors.salesPrice && (
                    <FormText>{state.errors.salesPrice}</FormText>
                  )}
                </div>
                <div className="col-md-12 form-group">
                  <label htmlFor="salesAmount">{t("المبلغ")}</label>
                  <CurrencyInput
                    id="salesAmount"
                    name="salesAmount"
                    className="form-control silver"
                    type="search"
                    defaultValue={state.formData.salesAmount}
                    value={state.formData.salesAmount}
                    decimalsLimit={2}
                    onValueChange={(value, name) => {
                      handleChangeAmount(value, name);
                    }}
                  />
                  {state.errors.salesAmount && (
                    <FormText>{state.errors.salesAmount}</FormText>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  const breadcrumb = [
    {
      link: "/",
      name: t("home"),
    },
    {
      link: props.situation === "1" ? "/exchange/seller" : "/exchange/buyer",
      name: props.situation === "1" ? t("شراالعملات") : t("بیع العملات"),
    },
  ];
  function renderBuyer() {
    return (
      <div className="col-md-4">
        <div className="form-row">
          <div className="card">
            <div className="card-body">
              <div className="form-row">
                <div className="form-row">
                  <div className="col-md-12 form-group">
                    <label htmlFor="buyerAccountId">{t("اسم اشراء")}</label>
                    <AsyncSelect
                      name="buyerAccountId"
                      id="buyerAccountId"
                      className="silver"
                      defaultOptions={buyerAccountOptions()}
                      value={selectedBuyerAccount}
                      loadOptions={changeBuyyerAccountQuery}
                      isClearable
                      defaultValue={
                        +props.situation === 2 &&
                        account && [
                          {
                            value: account.id,
                            label: account.name,
                          },
                        ]
                      }
                      onChange={(item) => {
                        if (item) {
                          setSelectedBuyerAccount(item);
                          state.formData.buyerAccountId = item.value;
                          updateTotalAmount();
                        }
                      }}
                      styles={customStyles}
                    />
                    {state.errors.buyerAccountId && (
                      <FormText>{state.errors.buyerAccountId}</FormText>
                    )}
                  </div>
                  <div className="col-md-12 form-group">
                    <label htmlFor="name">{t("فرع الحساب")}</label>
                    <Select
                      name="buyerSubAccountId"
                      id="buyerSubAccountId"
                      className="silver"
                      options={buyerSubAccountOptions()}
                      isSearchable
                      onChange={(item) => {
                        setSelectedBuyerSunAccount(item);
                        state.formData.buyerSubAccountId = item.value;
                        updateTotalAmount();
                      }}
                      styles={customStyles}
                    />
                    {state.errors.buyerSubAccountId && (
                      <FormText>{state.errors.buyerSubAccountId}</FormText>
                    )}
                  </div>
                  <div className="col-md-12 form-group">
                    <label htmlFor="name">{t("نوع العمله")}</label>
                    <Select
                      name="buyerCurrency"
                      id="buyerCurrency"
                      className="silver"
                      options={buyerCurrencyOptions()}
                      value={setBuyerCurrency()}
                      isSearchable
                      onChange={(item) => {
                        setSelectedBuyerCurrency(item);
                        state.formData.buyerCurrency = item.value;
                        updateTotalAmount();
                      }}
                      styles={customStyles}
                    />
                    {state.errors.buyerCurrency && (
                      <FormText>{state.errors.buyerCurrency}</FormText>
                    )}
                  </div>
                  <div className="col-md-12 form-group">
                    <label htmlFor="cashDeskBuyer">{t("الصندوق")}</label>

                    <input
                      type="checkbox"
                      name="cashDeskBuyer"
                      value="1"
                      onChange={(event) => {
                        handleChangeCashDesk(event);
                      }}
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <label htmlFor="buyerPrice">{t("سعر الشرا")}</label>
                    <CurrencyInput
                      id="buyerPrice"
                      name="buyerPrice"
                      className="form-control silver"
                      type="search"
                      value={buyerPrice()}
                      decimalsLimit={2}
                      onValueChange={(value, name) => {
                        handleChangeAmount(value, name);
                        updateTotalAmount();
                      }}
                    />
                    {state.errors.buyerPrice && (
                      <FormText>{state.errors.buyerPrice}</FormText>
                    )}
                  </div>
                  <div className="col-md-12 form-group">
                    <label htmlFor="buyerAmount">{t("المبلغ")}</label>
                    <CurrencyInput
                      id="buyerAmount"
                      name="buyerAmount"
                      className="form-control silver"
                      type="search"
                      value={state.formData.buyerAmount}
                      decimalsLimit={5}
                      disabled="disabled"
                    />
                    {state.errors.buyerAmount && (
                      <FormText>{state.errors.buyerAmount}</FormText>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  function render() {
    return (
      <div className="col-md-4">
        <div className="form-row">
          <div className="card">
            <div className="card-body">
              <div className="form-row">
                <div className="col-md-12 form-group">
                  <label htmlFor="id">{t("رقم الصیرفته")}</label>
                  <input
                    name="number"
                    id="number"
                    value={state.formData.number}
                    type="text"
                    className="form-control silver"
                  />
                  {state.errors.number && (
                    <FormText>{state.errors.number}</FormText>
                  )}
                </div>
                <div className="col-md-12 form-group">
                  <label htmlFor="date">{t("تاریخ الحواله")}</label>
                  <DatePicker
                    name="date"
                    id="date"
                    className="form-control silver"
                    value={state.formData.date}
                    dateFormat="yyyy/MM/dd"
                    selected={startDate}
                    strictParsing
                    closeOnScroll={true}
                    onChange={(date) => handleDateChange(date)}
                  />
                  {state.errors.date && (
                    <FormText>{state.errors.date}</FormText>
                  )}
                </div>
                <div className="col-md-12 forum-group">
                  <label htmlFor="name">{t("الملاحظات")}</label>
                  <textarea
                    name="description"
                    rows="5"
                    className="form-control silver"
                    value={state.formData.description}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <div className="row">
                      <div className="col col-footer">
                        <div className="buttons">
                          <Button type="submit" className="green">
                            <span className="icon-Save" />
                            {t("تسجیل")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Breadcrumb data={breadcrumb} />
      <form className="form" id="exchange-form" onSubmit={submitExchange}>
        <div className="form-row">
          {render()}
          {renderSeller()}
          {renderBuyer()}
        </div>
      </form>
    </div>
  );
};
export default Exchange;
