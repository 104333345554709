import React, { useEffect, useState } from "react";
import dataService from "../../services/data.service";
import axios from "axios";
import Token from "../auth/Token";
import { BaseUrl } from "./../../utilities/StaticProvider";
import Alert from "./../../utilities/Alert/AlertMessages";
import { useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import avii from "../../message/avii";
import Breadcrumb from "../Breadcrumb";
import translate from "../../message/avii";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
const Form = () => {
  const { t, i18n } = useTranslation();
  let { Id } = useParams();
  const [currency, setCurrency] = useState([]);
  const navigate = useNavigate();
  useEffect(async () => {
    let dataCurrency = {};
    if (Id) {
      dataCurrency = await axios.get(BaseUrl + "currencies/" + Id);
      setCurrency(dataCurrency.data.data);
    }
  }, []);
  const { token } = Token();
  let name = null;
  let purchase_price = null;
  let sales_price = null;
  let description = null;
  const modelDataService = new dataService(token, "currencies");
  let createOrUpdate = (e) => {
    e.preventDefault();
    let data = {
      name: name.value,
      purchase_price: purchase_price.value,
      sales_price: sales_price.value,
      description: description.value,
    };
    let title;
    let message = "";
    if (Id) {
      message = t("Updated successfully");
      title = t("update");
    } else {
      message = t("created successfully");
      title = t("create");
    }
    if (Id) {
      modelDataService
        .update(Id, data)
        .then((response) => {
          NotificationManager.success(
            t("Updated successfully"),
            message
          );
          return navigate("/currency");
        })
        .catch((e) => {
          NotificationManager.error(e + "", t("error"));
        });
    } else {
      modelDataService
        .create(data)
        .then((response) => {
          NotificationManager.success(title, message);
          return navigate("/currency");
        })
        .catch((e) => {
          console.log(e);
          NotificationManager.error(e + "", t("error")); // navigate('/')
        });
    }
  };

  function render() {
    return (
      <div className="form-row card">
        <div className="card-header">
          <span className="icon icon-Omala"></span>
          <span className="text-black">{t("create currency")}</span>
        </div>
        <div className="card-body">
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="exampleInputEmail1">{t("الاسم")}</label>
              <input
                defaultValue={currency.name}
                name="name"
                ref={(el) => (name = el)}
                type="text"
                className="inputStyle form-control "
                id="exampleInput"
                aria-describedby="name"
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="exampleInputEmail1">{t("قیمت خرید")}</label>
              <input
                ref={(el) => (purchase_price = el)}
                type="number"
                className="inputStyle form-control "
                id="exampleInput"
                aria-describedby="purchasePrice"
                defaultValue={currency.purchase_price}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="exampleInputEmail1">{t("قیمت فروش")}</label>
              <input
                ref={(el) => (sales_price = el)}
                type="number"
                className="inputStyle form-control "
                id="exampleInput"
                aria-describedby="salesPrice"
                defaultValue={currency.sales_price}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="exampleInputEmail1">{t("الملاحظات")}</label>
              <textarea
                ref={(el) => (description = el)}
                className="inputStyle form-control "
                defaultValue={currency.description}
                rows="5"
              ></textarea>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <button
                type="submit"
                id="submit2"
                className="btn btn-sm btn-success margin30"
              >
                {t("تسجیل")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  const breadcrumb = [
    {
      link: "/",
      name: t("home"),
    },
    {
      link: "/setting",
      name: t("اعدادات"),
    },
    {
      link: "/currency",
      name: t("قائمة"),
    },
    {
      link: Id ? "/currency/update" : "/currency/create/",
      name: Id ? t("تعدیل") : t("انشاء"),
    },
  ];
  return (
    <div>
      <Breadcrumb data={breadcrumb} />

      <form className="form" id="currency-form" onSubmit={createOrUpdate}>
        <div className="form-row">{render()}</div>
      </form>
    </div>
  );
};
export default Form;
