import React from "react";
import { FormText } from "react-bootstrap";
const FormGroup = props => {
  return <div className="form-group">
        <label htmlFor={props.id}>{props.label} :</label>
        <input name={props.name} id={props.id} value={props.value} disabled={props.disabled} type={props.type} className={'form-control ' + props.class} onChange={props.handleChange} />
        {props.errors && <FormText>{props.errors}</FormText>}
        {props.children}
    </div>;
};
export default FormGroup;