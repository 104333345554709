import React, { useEffect, useState } from "react";
import ChequeIn from "../cheque-item/ChequeIn";
import ChequeOut from "../cheque-item/ChequeOut";
import Button from "../button/Button";
import dataService from "../../services/data.service";
import FormGroup from "../formGroup/formGroup";
import Token from "../auth/Token";
import { useNavigate, useParams } from "react-router";
import translate from "../../message/avii";
import {
  isContainWhiteSpace,
  isEmpty,
  isLength,
  isUsername,
} from "../../shared/validator";
import { NotificationManager } from "react-notifications";
import Breadcrumb from "../Breadcrumb";
import { FormText } from "react-bootstrap";
import { BaseUrl } from "../../utilities/StaticProvider";
import { useTranslation } from "react-i18next";
const UserForm = (props) => {
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    formData: {
      id: "",
      username: "",
      password: "",
      password_confirmation: "",
      permissions: [],
      cashDeskId: 0,
    },
    errors: {},
    formSubmitted: false,
    loading: false,
  });
  const [cashDesks, setCashDesks] = useState([]);
  const navigate = useNavigate();
  let { Id } = useParams();
  const { token } = Token();
  const userDataService = new dataService(token, "users");
  const cashDeskDataService = new dataService(token, "cashDesk");
  useEffect(() => {
    getCashDesk();
    if (Id) {
      userDataService.get(Id).then((result) => {
        let user = result.data.data;
        setState({
          ...state,
          formData: user,
        });
      });
    }
  }, []);
  async function getCashDesk() {
    const response = await fetch(BaseUrl + "cashDesk");
    const result = await response.json();
    setCashDesks(result.data);
  }
  function handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let { formData } = state;
    formData[name] = value;
    setState({
      ...state,
      formData: formData,
    });
    console.log(state);
  }
  const validateLoginForm = (e) => {
    let errors = {};
    const { formData } = state;

    //username
    if (isEmpty(formData.username)) {
      errors.username = t("username can't be blank");
    } else if (!isUsername(formData.username)) {
      errors.username = t("Please enter a valid username");
    } else if (
      !isLength(formData.username, {
        gte: 4,
        trim: true,
      })
    ) {
      errors.username = t("username's length must greater than 6");
    }

    //password
    if (isEmpty(formData.password)) {
      errors.password = t("Password can't be blank");
    } else if (isContainWhiteSpace(formData.password)) {
      errors.password = t("Password should not contain white spaces");
    } else if (
      !isLength(formData.password, {
        gte: 6,
        lte: 16,
        trim: true,
      })
    ) {
      errors.password = t("Password's length must between 6 to 16");
    }

    //c_password
    if (isEmpty(formData.password_confirmation)) {
      errors.password_confirmation = t("Password can't be blank");
    } else if (isContainWhiteSpace(formData.password_confirmation)) {
      errors.password_confirmation = t(
        "Password should not contain white spaces"
      );
    } else if (
      !isLength(formData.password_confirmation, {
        gte: 6,
        lte: 16,
        trim: true,
      })
    ) {
      errors.password_confirmation = t(
        "Password's length must between 6 to 16"
      );
    }
    if (formData.password !== formData.password_confirmation) {
      errors.password = t(
        "Your password and confirmation password do not match."
      );
    }
    if (isEmpty(errors)) {
      return true;
    } else {
      return errors;
    }
  };
  async function handleSubmit(event) {
    event.preventDefault();
    let errors = validateLoginForm();
    if (errors === true) {
      saveUser();
    } else {
      setState({
        ...state,
        errors: errors,
        formSubmitted: true,
      });
    }
  }
  function saveUser() {
    const { formData } = state;
    if (formData.cashDeskId === 0) {
      delete formData.cashDeskId;
    }
    let title;
    let message = "";
    if (Id) {
      message = t("Updated successfully");
      title = t("update");
    } else {
      message = t("created successfully");
      title = t("create");
    }
    if (formData.id) {
      userDataService
        .update(formData.id, formData)
        .then((response) => {
          this.setState(...state, {
            id: response.data.id,
            name: response.data.name,
          });
          NotificationManager.success(message, title);
          navigate("/users");
        })
        .catch((e) => {
          NotificationManager.error(t("error"), title);
        });
    } else {
      userDataService
        .create(formData)
        .then((response) => {
          if (response.data.success) {
            NotificationManager.success(message, title);
            navigate("/users");
          } else {
            NotificationManager.success(message, title);
            setState({
              ...state,
              errors: response.data,
              formSubmitted: true,
            });
            navigate("/users");
          }
        })
        .catch((e) => {
          title = t("error");
          NotificationManager.error(t("error"), title);
        });
    }
  }
  const breadcrumb = [
    {
      link: "/",
      name: t("home"),
    },
    {
      link: "/setting",
      name: t("اعدادات"),
    },
    {
      link: "/users",
      name: t("قائمة"),
    },
    {
      link: Id ? "/users/update/" + Id : "/users/create",
      name: Id ? t("update") : t("create"),
    },
  ];
  return (
    <div>
      <Breadcrumb data={breadcrumb} />
      <div className="form-row  card-body">
        <div className="col-md-12">
          <form className="form card" id="user-form">
            <h5>{t("تعریف المستخدم")}</h5>
            <div className="form-row">
              {state.errors.result && (
                <div className="alert alert-danger" role="alert">
                  {state.errors.result}
                </div>
              )}
            </div>
            <div className="row  center">
              <div className="col-md-6">
                <FormGroup
                  name="id"
                  id="id"
                  class="silver"
                  value={state.formData.id}
                  disabled="disabled"
                  type="text"
                  errors={state.errors && state.errors.id}
                  handleChange={handleChange}
                  label={t("رقم المستخدم")}
                />

                <FormGroup
                  name="username"
                  id="username"
                  class="silver"
                  value={state.formData.username}
                  errors={state.errors && state.errors.username}
                  type="text"
                  handleChange={handleChange}
                  label={t("اسم المستخدم")}
                />

                <FormGroup
                  name="password"
                  id="password"
                  class="silver"
                  type="password"
                  value={state.formData.password}
                  errors={state.errors && state.errors.password}
                  handleChange={handleChange}
                  label={t("کلمه العبور")}
                />

                <FormGroup
                  name="password_confirmation"
                  id="password_confirmation"
                  class="silver"
                  type="password"
                  value={state.formData.password_confirmation}
                  errors={state.errors && state.errors.password_confirmation}
                  handleChange={handleChange}
                  label={t("کلمه العبور")}
                />

                <div className="form-group">
                  <label htmlFor="cashDeskId">{t("حساب دیفالت")}</label>
                  <select
                    className="form-control silver"
                    name="cashDeskId"
                    onChange={handleChange}
                    value={state.formData.cashDeskId}
                    id="cashDeskId"
                  >
                    <option value="0" selected>
                      {t("choose")}
                    </option>

                    {cashDesks.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                  {state.errors.currency_id && (
                    <FormText>{state.errors.currency_id}</FormText>
                  )}
                </div>
              </div>
            </div>

            <div className="row center ">
              <div className="buttons center">
                <Button
                  handleClick={handleSubmit}
                  type="submit"
                  className="green"
                >
                  <span className="icon-Save" />
                  {t("تسجیل")}
                </Button>
                <Button handleClick={() => {}} className="blue">
                  <span className="icon-Cancel" />
                  {t("الغاء")}
                </Button>
              </div>
            </div>
            {/*<div className="row col-md-12">*/}
            {/*<div className="col-md-2">*/}
            {/*        <div className="form-group">*/}
            {/*            <label className="el-switch el-switch-lg" htmlFor="permissions-1">*/}
            {/*                <span className="margin-l">{translate.ar.global.cheque}</span>*/}
            {/*                <input type="checkbox" name="permissions[]" id="permissions-1"/>*/}
            {/*                <span className="el-switch-style"/>*/}
            {/*            </label>*/}
            {/*        </div>*/}

            {/*</div>*/}
            {/*<div className="col-md-2">*/}

            {/*        <div className="form-group">*/}
            {/*            <label className="el-switch el-switch-lg" htmlFor="permissions-2">*/}
            {/*                <span className="margin-l">{translate.ar.global.exchange}</span>*/}
            {/*                <input type="checkbox" name="permissions[]" id="permissions-2"/>*/}
            {/*                <span className="el-switch-style"/>*/}
            {/*            </label>*/}
            {/*        </div>*/}

            {/*</div>*/}
            {/*<div className="col-md-2">*/}

            {/*        <div className="form-group">*/}
            {/*            <label className="el-switch el-switch-lg" htmlFor="permissions-2">*/}
            {/*                <span className="margin-l">{translate.ar.global.exchange}</span>*/}
            {/*                <input type="checkbox" name="permissions[]" id="permissions-2"/>*/}
            {/*                <span className="el-switch-style"/>*/}
            {/*            </label>*/}
            {/*        </div>*/}

            {/*</div>*/}

            {/*<div className="col-md-2">*/}

            {/*        <div className="form-group">*/}
            {/*            <label className="el-switch el-switch-lg" htmlFor="permissions-3">*/}
            {/*                <span className="margin-l">{translate.ar.global.transfer}</span>*/}
            {/*                <input type="checkbox" name="permissions[]" id="permissions-3"/>*/}
            {/*                <span className="el-switch-style"/>*/}
            {/*            </label>*/}
            {/*        </div>*/}

            {/*</div>*/}

            {/*<div className="col-md-2">*/}

            {/*        <div className="form-group">*/}
            {/*            <label className="el-switch el-switch-lg" htmlFor="permissions-4">*/}
            {/*                <span className="margin-l">   {translate.ar.global.tracking}</span>*/}
            {/*                <input type="checkbox" name="permissions[]" id="permissions-4"/>*/}
            {/*                <span className="el-switch-style"/>*/}
            {/*            </label>*/}
            {/*        </div>*/}

            {/*</div>*/}
            {/*<div className="col-md-2">*/}

            {/*        <div className="form-group">*/}
            {/*            <label className="el-switch el-switch-lg" htmlFor="permissions-4">*/}
            {/*                <span className="margin-l">   {translate.ar.global.tracking}</span>*/}
            {/*                <input type="checkbox" name="permissions[]" id="permissions-4"/>*/}
            {/*                <span className="el-switch-style"/>*/}
            {/*            </label>*/}
            {/*        </div>*/}
            {/*</div>*/}

            {/*</div>*/}
          </form>
        </div>
      </div>
    </div>

    // <div >
    //     <Breadcrumb data={breadcrumb}/>
    //     <div className="col-md-12">
    //         <form className="form user-form" id="user-form">
    //             <h5>تعریف المستخدم</h5>
    //             <div className="form-row">
    //                 {state.errors.result &&
    //                 <div className="alert alert-danger" role="alert">
    //                     {state.errors.result}
    //                 </div>
    //                 }
    //             </div>
    //             <div className="row  center">
    //
    //                 <div className="col-md-6">
    //
    //                     <FormGroup name="id"
    //                                id="id"
    //                                class="silver"
    //                                value={state.formData.id}
    //                                disabled = 'disabled'
    //                                type="text"
    //                                errors={state.errors && state.errors.id}
    //                                handleChange={handleChange}
    //                                label="رقم المستخدم"
    //
    //                     />
    //
    //                     <FormGroup name="username"
    //                                id="username"
    //                                class="silver"
    //                                value={state.formData.username}
    //                                errors={state.errors && state.errors.username}
    //                                type="text"
    //                                handleChange={handleChange}
    //                                label="اسم المستخدم"
    //
    //                     />
    //
    //
    //                     <FormGroup name="password"
    //                                id="password"
    //                                class="silver"
    //                                type="password"
    //                                value={state.formData.password}
    //                                errors={state.errors && state.errors.password}
    //                                handleChange={handleChange}
    //                                label="کلمه العبور"
    //
    //                     />
    //
    //                     <FormGroup name="password_confirmation"
    //                                id="password_confirmation"
    //                                class="silver"
    //                                type="password"
    //                                value={state.formData.password_confirmation}
    //                                errors={state.errors && state.errors.password_confirmation}
    //                                handleChange={handleChange}
    //                                label="کلمه العبور"
    //
    //                     />
    //
    //
    //                 </div>
    //                 <div className="col-md-6">
    //
    //                     <div className="form-row">
    //                         <div className="form-group">
    //                             <label className="el-switch el-switch-lg" htmlFor="permissions-1">
    //                                 <span className="margin-l">{translate.ar.global.cheque}</span>
    //                                 <input type="checkbox" name="permissions[]" id="permissions-1"/>
    //                                 <span className="el-switch-style"/>
    //                             </label>
    //                         </div>
    //                     </div>
    //
    //                     <div className="form-row">
    //                         <div className="form-group">
    //                             <label className="el-switch el-switch-lg" htmlFor="permissions-2">
    //                                 <span className="margin-l">{translate.ar.global.exchange}</span>
    //                                 <input type="checkbox" name="permissions[]" id="permissions-2"/>
    //                                 <span className="el-switch-style"/>
    //                             </label>
    //                         </div>
    //                     </div>
    //
    //                     <div className="form-row">
    //                         <div className="form-group">
    //                             <label className="el-switch el-switch-lg" htmlFor="permissions-3">
    //                                 <span className="margin-l">{translate.ar.global.transfer}</span>
    //                                 <input type="checkbox" name="permissions[]" id="permissions-3"/>
    //                                 <span className="el-switch-style"/>
    //                             </label>
    //                         </div>
    //                     </div>
    //
    //                     <div className="form-row">
    //                         <div className="form-group">
    //                             <label className="el-switch el-switch-lg" htmlFor="permissions-4">
    //                                 <span className="margin-l">   {translate.ar.global.tracking}</span>
    //                                 <input type="checkbox" name="permissions[]" id="permissions-4"/>
    //                                 <span className="el-switch-style"/>
    //                             </label>
    //                         </div>
    //                     </div>
    //
    //                     <div className="form-row">
    //                         <div className="form-group">
    //                             <label className="el-switch el-switch-lg" htmlFor="permissions-5">
    //                                 <span className="margin-l">{translate.ar.global.setting}</span>
    //                                 <input type="checkbox" name="permissions[]" id="permissions-5"/>
    //                                 <span className="el-switch-style"/>
    //                             </label>
    //                         </div>
    //                     </div>
    //
    //                 </div>
    //             </div>
    //
    //             <div className="row">
    //                 <div className="col">
    //                     <div className="buttons center">
    //                         <Button
    //                             handleClick={handleSubmit}
    //                             type="submit"
    //                             className="green"
    //                         >
    //                             <span className="icon-Save"/>تسجیل
    //                         </Button>
    //                         <Button
    //                             handleClick={() => {
    //                             }}
    //                             className="blue"
    //                         >
    //                             <span className="icon-Cancel"/>الغاء
    //                         </Button>
    //                     </div>
    //                 </div>
    //             </div>
    //
    //         </form>
    //     </div>
    // </div>
  );
};

export default UserForm;
