import Loader from "react-loader-spinner";
import React from "react";
import Logo from "../logo/logo";
import { usePromiseTracker } from "react-promise-tracker";
const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
      </div>
    )
  );
};
export default LoadingIndicator;
