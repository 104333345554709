import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./breadcrumb.css";
const Breadcrumb = (params) => {
  return (
    <div className="form-row">
      <div className="col-md-12">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb custom-breadcrumb">
            <i className="icon icon-HomeDashbord" />&nbsp;
            {params.data.map((item, index) => {
              return (
                <li className="breadcrumb-item" key={index}>
                  <Link to={item.link}>{item.name}</Link>
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
    </div>
  );
};
export default Breadcrumb;
