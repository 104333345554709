import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import translate from "../../message/avii";
import { NotificationManager } from "react-notifications";
import Breadcrumb from "../Breadcrumb";
import Button from "../button/Button";
import dataService from "../../services/data.service";
import Token from "../auth/Token";
import "./jaradForm.css";
import { BaseUrl } from "../../utilities/StaticProvider";
import CurrencyInput from "react-currency-input-field";
import { useNavigate } from "react-router";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";
const JardForm = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [jard, setJard] = useState([]);
  const [jard_total, setJardTotal] = useState({
    base: 0,
    currencies: [],
  });
  const [state, setState] = useState([]);
  const [currencyOfficial, setCurrencyOfficial] = useState({
    id: 0,
    name: "",
    salesPrice: 0,
  });
  let { Id } = useParams();
  const loadData = async () => {
    const response = await fetch(
      Id ? BaseUrl + "jard/" + Id : BaseUrl + "jard/create"
    );
    const dataJson = await response.json();
    let object = {
      currencies: {},
      description: dataJson.data.description ? dataJson.data.description : "",
    };
    let totalJard = 0;
    if (Id) {
      dataJson.data.currencies.forEach((item) => {
        object.currencies[item.currency_id] = {
          currency: item.currency,
          accountsAmount: item.currency_amount,
          cheque: item.chequre_amount,
          sum: item.sum,
          newSum: item.newSum ? item.sum : item.sum + item.custom_amount,
          currency_price_history_id: item.currency_price_history_id,
          currency_base_price_ratio:
            item.currency_base_price_ratio > 0
              ? item.currency_base_price_ratio
              : item.currency.sales_price /
                dataJson.data.currencyOfficial.salesPrice,
          sumAll:
            (item.sum + item.custom_amount) * item.currency_base_price_ratio,
          other: item.custom_amount ? item.custom_amount : 0,
        };
      });
      setCurrencyOfficial(dataJson.data.currencyOfficial);
    } else {
      dataJson.data.currencies.forEach((item) => {
        object.currencies[item.currency.id] = {
          currency: item.currency,
          accountsAmount: item.accountsAmount,
          cheque: item.cheque,
          sum: item.sum - item.cheque,
          newSum: item.newSum ? item.newSum : item.sum - item.cheque,
          currency_price_history_id: item.currency_price_history_id,
          currency_base_price_ratio:
            item.currency_base_price_ratio > 0
              ? item.currency_base_price_ratio
              : item.currency.sales_price /
                dataJson.data.currencyOfficial.salesPrice,
          sumAll: item.currency_base_price_ratio
            ? (item.newSum ? item.newSum : item.sum - item.cheque) *
              item.currency_base_price_ratio
            : (item.newSum ? item.newSum : item.sum - item.cheque) *
              (item.currency.sales_price /
                dataJson.data.currencyOfficial.salesPrice),
          other: item.other ? item.other : 0,
        };
      });
      setCurrencyOfficial(dataJson.data.currencyOfficial);
    }
    setState(object);
    Object.values(object.currencies).forEach((item) => {
      totalJard += item.sumAll;
    });
    let crrs = [];
    Object.values(object.currencies).forEach((item) => {
      crrs[item.currency.id] = totalJard / item.currency_base_price_ratio;
    });
    setJardTotal({
      base: totalJard,
      currencies: crrs,
    });
    setJard(Object.values(object.currencies));
  };
  useEffect(async () => {
    loadData();
  }, []);
  const { token } = Token();
  const modelDataService = new dataService(token, "jard");
  function save(event) {
    event.preventDefault();
    let currencies = [];
    Object.entries(state.currencies).forEach(([key, value]) => {
      currencies.push(value);
    });
    const formData = {
      data: currencies,
      description: state.description,
      total: jard_total.base,
      officialCurrencyId: currencyOfficial.id,
      officialCurrencyPrice: currencyOfficial.salesPrice,
      officialCurrencyName: currencyOfficial.name,
    };
    if (Id) {
      modelDataService
        .update(Id, formData)
        .then((response) => {
          NotificationManager.success(
            t("Updated successfully"),
            t("UpdateJarad")
          );
          return navigate("/jard");
        })
        .catch((e) => {
          NotificationManager.error(t("error"), t("UpdateJarad"));
        });
    } else {
      modelDataService
        .create(formData)
        .then((response) => {
          NotificationManager.success(
            t("created successfully"),
            t("createJarad")
          );
          return navigate("/jard");
        })
        .catch((e) => {
          NotificationManager.error(t("error"), t("createJarad")); // navigate('/')
        });
    }
  }

  function handleChange(event, currencyId) {
    let data = state.currencies;
    Object.entries(data).forEach(([key, value]) => {
      if (key == currencyId) {
        if (event.target.name === "number")
          data[key].other = parseInt(event.target.value);
        else if (event.target.name === "currency_base_price_ratio")
          data[key].currency_base_price_ratio = parseInt(event.target.value);
      }
    });
    setState({
      ...state,
      currencies: data,
    });
    recalculateSums();
  }
  function recalculateSums() {
    let data = state.currencies;
    let totalJard = 0;
    Object.entries(data).forEach(([key, value]) => {
      value.newSum =
        parseInt(value.sum) + parseInt(value.other) - parseInt(value.cheque);
      value.sumAll = value.newSum * value.currency_base_price_ratio;
      totalJard += value.sumAll;
    });
    let crrs = [];
    Object.values(data).forEach((item) => {
      crrs[item.currency.id] = totalJard / item.currency_base_price_ratio;
    });
    setJardTotal({
      base: totalJard,
      currencies: crrs,
    });
    setState({
      ...state,
      currencies: data,
    });
  }
  function handelDescription(event) {
    setState({
      ...state,
      description: event.target.value,
    });
  }
  const breadcrumb = [
    {
      link: "/",
      name: t("home"),
    },
    {
      link: "/jard",
      name: t("قائمة"),
    },
    {
      link: "/jard/create",
      name: t("جرد"),
    },
  ];
  return (
    <div class="col-md-12">
      <Breadcrumb data={breadcrumb} />
      <form className="form card">
        <div className="card-body ">
          <div className="row">
            <div className="col-md-12">
              <tabel className="table table-responsive w-100 d-block d-md-table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">{t("الحسابات")}</th>
                    <th scope="col">{t("غیرمستمله")}</th>
                    <th scope="col">{t("ادخل يدوي")}</th>
                    <th scope="col">{t("المجموع")}</th>
                    <th scope="col">{t("سعر")}</th>
                    <th scope="col">{t("الکل")}</th>
                  </tr>
                </thead>
                <tbody>
                  {jard.map((item) => {
                    return (
                      <tr>
                        <td>{item.currency.name}</td>
                        <td>
                          <NumberFormat
                            value={item.accountsAmount ?? item.currency_amount}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={""}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={item.cheque ?? item.chequre_amount}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={""}
                          />
                        </td>
                        <td className="w-10">
                          <CurrencyInput
                            id="number"
                            name="number"
                            className="form-control silver"
                            decimalsLimit={2}
                            value={item.other ?? item.custom_amount}
                            onValueChange={(value, name) =>
                              handleChange(
                                {
                                  target: {
                                    value,
                                    name,
                                  },
                                },
                                item.currency.id
                              )
                            }
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={state.currencies[item.currency.id].newSum}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={""}
                          />
                        </td>
                        <td className="w-10">
                          <CurrencyInput
                            id="currency_base_price_ratio"
                            name="currency_base_price_ratio"
                            className="form-control silver"
                            decimalsLimit={2}
                            value={
                              item.currency_base_price_ratio > 0
                                ? item.currency_base_price_ratio
                                : item.currency.sales_price /
                                  currencyOfficial.salesPrice
                            }
                            onValueChange={(value, name) =>
                              handleChange(
                                {
                                  target: {
                                    value,
                                    name,
                                  },
                                },
                                item.currency.id
                              )
                            }
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={state.currencies[item.currency.id].sumAll}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={""}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot className="bg-info text-white">
                  <td>
                    {t("المجموع")} :
                    <NumberFormat
                      value={jard_total.base}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={""}
                    />
                  </td>
                  {jard_total.currencies?.map((currency, index) => (
                    <td key={index}>
                      {state.currencies[index].currency.name} :{" "}
                      <NumberFormat
                        value={currency.toFixed(0)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={""}
                      />
                    </td>
                  ))}
                  <td colspan="100%"></td>
                </tfoot>
              </tabel>
            </div>
          </div>

          <div className="col-md-12 form-group">
            <label htmlFor="exampleInputEmail1">{t("توضیحات")}</label>
            <textarea
              className="inputStyle form-control "
              rows="5"
              name="description"
              defaultValue={state.description}
              onChange={(el) => {
                handelDescription(el);
              }}
            ></textarea>
          </div>
          <div className="row">
            <div className="col">
              <div className="buttons center">
                <Button type="submit" className="green" handleClick={save}>
                  <span className="icon-Save" />
                  {t("تسجیل")}
                </Button>
                <Button className="blue">
                  <span className="icon-Cancel" />
                  {t("الغاء")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default JardForm;
