import $ from "jquery";
function Alerts(message, className, history = null, redirectUrl = null) {
  $("#message").toggleClass(className);
  $("#message").show();
  $("#message").html("" + "<p><b>" + message + "</b></p>" + "");
  setTimeout(() => {
    $(".alert").slideUp(700);
  }, 4000);
  if (history && redirectUrl) {
    setTimeout(() => {
      history.replace(redirectUrl);
    }, 5000);
  }
}
export default Alerts;
