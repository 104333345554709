import React, { useEffect, useState } from "react";
import "./CustomerList.css";
import Datatable from "../dataTable/DataTable";
import dataService from "../../services/data.service";
import Token from "../auth/Token";
import Parser from "html-react-parser";
import moment from "moment";
import Breadcrumb from "../Breadcrumb";
import { useTranslation } from "react-i18next";
const CustomerList = (props) => {
  const { t, i18n } = useTranslation();
  const config = {
    create: {
      link: "./create",
      icon: "icon-Plus",
      title: t("انشاء مشتری"),
    },
  };
  const columns = [
    {
      key: "id",
      title: t("الترتیب"),
      value: function (model) {
        return model.id;
      },
    },
    {
      key: "name",
      title: t("اسم المشتری"),
      value: function (model) {
        return Parser(`<div class="account-info">
                                <div className="customer-avatar">
                                    <img src="./assets/images/man.png" alt="">
                                </div>
                                <div className="account-name"> ${model.name}</div>
                            </div>
                        `);
      },
    },
    {
      key: "email",
      title: t("ایمیل"),
      value: function (model) {
        return model.email;
      },
    },
    {
      key: "identificationNumber",
      title: t("شماره شناسایی"),
      value: function (model) {
        return model.identificationNumber;
      },
    },
    {
      key: "mobile",
      title: t("موبایل"),
      value: function (model) {
        return model.mobile;
      },
    },
    {
      key: "created_at",
      title: t("تاريخ الانشاء"),
      value: function (model) {
        return moment(model.created_at).format("YYYY/MM/DD hh:mm:ss");
      },
    },
  ];
  const actions = [
    {
      link: "/customer/update/{id}",
      icon: "icon icon-Edit",
      type: "link",
    },
    {
      link: "/customer/delete/{id}",
      icon: "icon icon-Cancel",
      type: "delete",
    },
  ];
  const breadcrumb = [
    {
      link: "/",
      name: t("home"),
    },
    {
      link: "/customer",
      name: t("قائمة"),
    },
  ];
  const { token } = Token();
  const ds = new dataService(token, "customers");
  return (
    <div class="col-md-12">
      <Breadcrumb data={breadcrumb} />
      <Datatable
        entity="users"
        dataService={ds}
        columns={columns}
        actions={actions}
        config={config}
      />
    </div>
  );
};
export default CustomerList;
